import React from 'react';
import {Modal} from 'react-bootstrap';

const TrustFundModal = (props) => {
    return (
        <Modal
            style={{zIndex: 10000}}
            show={props.show}
            //    centered
            size="l"
            onHide={props.onClose}
        >
            <Modal.Header
                closeButton
                style={{padding: 20, backgroundColor: '#334155', color: '#fff', borderRadius: 0}}

            >
                Tsigiro Usekelo Trust Fund
            </Modal.Header>
            <Modal.Body>
                <p>Register to become a member of the Tsigiro Trust Fund to enjoy exclusive benefits</p>
                {/* <p style={{marginTop:20}}>Monthly ZAR 120.00</p> */}
                <button className='btn btn-primary'>
                    Subscribe Now For ZAR 120.00/month
                </button>
            </Modal.Body>
        </Modal>
    )
}

export default TrustFundModal