/* eslint-disable max-len */
const FLATDATA = [
  {
    id: 1,
    name: "Tatenda Chakwesha",
    accountBalance: 10000,
  },
];

export default FLATDATA;
