import React, {useRef} from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Navbar from "../global-components/navbar";
import Footer_v1 from "../global-components/footer";
import LoadingBox from "./LoadingBox";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import FeaturesV1 from "../section-components/features-v1";
import Page_header from "../global-components/page-header";
import ShopLeftSidebarPage from "../shop-left-sidebar";
import {useQuery} from "react-query";
import PropertyService from "../../api/PropertyService";
import LoadingBar from "react-top-loading-bar";


const PropertyPurchases = () => {

  const [location, setLocation] = useState("");
  const [type, setType] = useState("");
  const [size, setSize] = useState("");
  const [price, setPrice] = useState("");
  const [timeline, setTimeline] = useState("");

  let publicUrl = process.env.PUBLIC_URL+'/';

  const ref = useRef(null);

  const { data:userData, isLoading}= useQuery("user-purchases",PropertyService.getUserPurchases,{
	onSettled:()=>{
		ref.current.complete();
	}
  })
  console.log(userData);

  return (
    <div className="ltn__shop-details-area pb-10">
      <Navbar />
           <Page_header headertitle="My Property Purchases" />
		   <LoadingBar ref={ref} color="green" progress={5} />
           <div>
			<div className="ltn__product-area ltn__product-gutter mb-100">
				<div className="container">
						<div className="row">
							<div className="col-lg-12">
							<div className="tab-content ">
								<div className="tab-pane active fade" id="liton_product_list">
								<div className="ltn__product-tab-content-inner ltn__product-list-view">
									{
										!isLoading && (userData?.data?.purchaseRequests).length === 0 ? <>
										<h2 className="product-title go-top">No Active Purchases</h2>
									<div className="product-img-location">
										<p>You can see all your active property purchases here</p>
									</div>
										</> : <>
										<h2 className="product-title go-top">Your Property Purchases</h2>
										<div className="product-img-location">
											<p>You can see all your active property purchases here</p>
										</div>
										</>
									}
									
									
									{
										!isLoading && (userData?.data?.purchaseRequests).map((item,index)=>{
										return <div className="row" key={index}>
												<div className="col-lg-12">
													<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
													<div className="product-img">
														<a href={"/product-details?"+item?.property?._id}><img src={process.env.REACT_APP_SPACES_URL+item?.property?.propertyImages[0]} alt="#" /></a>
													</div>
													<div className="product-info">
														<div className="product-badge-price">
														<div className="product-badge">
															<ul>
															<li className="sale-badg">{item?.property?.propertyStatus}</li>
															</ul>
														</div>
														<div className="product-price">
															<span>${item?.property?.price}<label>
																{/* /Month */}
																</label></span>
														</div>
														</div>
														<h2 className="product-title go-top">{item?.property?.title}</h2>
														<div className="product-img-location">
														<ul>
															<li className="go-top">
															<i className="flaticon-pin" />{item?.property?.city?.name}, {item?.property?.subLocation?.name}
															</li>
														</ul>
														</div>
														<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
														{
															item?.property?.bedrooms && <li><span>{item?.property?.bedrooms} </span>
															Bedrooms
														</li>}
														{
															item?.property?.bathrooms && <li><span>{item?.property?.bathrooms} </span>
															Bathrooms
														</li>}
														
														{
															item?.property?.bedrooms && <li><span>{item?.property?.lounge} </span>
															Lounge
														</li>}
														{
															item?.property?.bathrooms && <li><span>{item?.property?.kitchen} </span>
															Kitchen
														</li>}

														<li><span>{item?.property?.propertySize} </span>
															Square Meters
														</li>
														</ul>
													</div>
													<div className="product-info-bottom">
														<div className="real-estate-agent">
														<div className="agent-img">
															<Link to="/team-details"><img src={publicUrl+"assets/img/blog/author.jpg"} alt="#" /></Link>
														</div>
														<div className="agent-brief go-top">
															<h6>{item?.property?.agent?.firstName} {item?.property?.agent?.lastName}</h6>
															<small>{item?.property?.agent?.email}</small>
														</div>
														</div>
														<div className="product-hover-action">
														<ul>
															<li>
															<a href={`https://wa.me/${item?.property?.agent?.phone}`} target="_blank">
																<i className="icon-whatsapp" />
															</a>
															</li>
															<li>
																<a href={`mailto:${item?.property?.agent?.email}`}>
																	<i className="icon-mail" />
																</a>
															</li>
															<li>
																<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
																	<i className="icon-phone-call" />
																</a>
															</li>
														</ul>
														</div>
													</div>
													</div>
												</div>																
											</div>
										})
									}
									
								</div>
								</div>
							</div>
							{/* <div className="ltn__pagination-area text-center">
								<div className="ltn__pagination">
								<ul>
									<li><a href="#"><i className="fas fa-angle-double-left" /></a></li>
									<li><a href="#">1</a></li>
									<li className="active"><a href="#">2</a></li>
									<li><a href="#">3</a></li>
									<li><a href="#">...</a></li>
									<li><a href="#">10</a></li>
									<li><a href="#"><i className="fas fa-angle-double-right" /></a></li>
								</ul>
								</div>
							</div> */}
							</div>
						</div>
				</div>
			</div>
			</div>
      <Footer_v1 />
    </div>
  );
};

export default PropertyPurchases;
