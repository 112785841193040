import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import ModalRequest from "../functional-components/ModalRequest";

class AboutV1 extends Component {

    constructor(props){
      super(props);
      this.state = {
          edit:false,
          view:false,
          requestStatus:"",
          disabled:false
      }
    }

  render() {

    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pt-120 pb-90 " style={{paddingTop:0}}>
			<div className="ltn__modal-area ltn__add-to-cart-modal-area">
			</div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color" style={{fontWeight:400,color:'#000'}}>
                    Mange Your Property Requests
                  </h6>
                   {
                  this.props.children
                 }
                </div>
                <div className="ltn__callout bg-overlay-theme-05  mt-30">
                  <p>
                    We're making property ownership easy and inclusive, for the first
                    time ever, you can submit property requests on where you would love to own a property or stand and we'll help you find what you need in no time
                  </p>
                </div>
                <div className="btn-wrapper animated go-top">
                     <div className="mini-cart-icon mini-cart-icon-2">
								<a href="#ltn__utilize-cart-menu" 
                  onClick={()=>{
                        this.setState({requestStatus:'Create A New Request',disable:false})
                  }}
                className="ltn__utilize-toggle">
                   <Link
                    className="theme-btn-1 btn btn-effect-1 ltn__utilize-toggle"
                  >
                    Submit New Request
                  </Link>
								</a>
							</div>  
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* submit new request */}
       <div
          id="ltn__utilize-cart-menu"
          className="ltn__utilize ltn__utilize-cart-menu"
        >
          <div className="ltn__utilize-menu-inner ltn__scrollbar">
            <div className="ltn__utilize-menu-head">
              <span className="ltn__utilize-menu-title">{this.state.requestStatus}</span>
              <button className="ltn__utilize-close">×</button>
            </div>
             <div className="car-dealer-form-inner">
                  <form
                      // action="/property-request-success"
                      className="ltn__car-dealer-form-box row"
                      id="request-form"
                      // onSubmit={handleRequestSubmission}
                  >
                      <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-12 col-md-12">
                          <select 
                              disabled={this.state.disabled}
                              value={this.state.propertyType}
                              className="nice-select" name="propertyType" >
                              <option value="null">Property Type</option>
                              <option value="appartment">Apartment</option>
                              <option value="commercial">Commercial Property</option>
                              <option value="gardenflat">Garden Flats</option>
                              <option value="familyhome">Single Family Home</option>
                          </select>
                      </div>
                      <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-12 col-md-12">
                          <select 
                          disabled={this.state.disabled}
                          className="nice-select" name="city"
                          value={this.state.city}
                          >
                              <option value="harare">Harare</option>
                              <option value="mashonlandWest">Mashonaland West</option>
                              <option value="mashonlandEast">Mashonaland East</option>
                              <option value="manicaland">Manicaland</option>
                              <option value="masvingo">Masvingo</option>
                              <option value="matebelandNorth">Matebeleland North</option>
                              <option value="matebelandNorth">Matebeleland South</option>
                          </select>
                      </div>
                      <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-12 col-md-12">
                          <select 
                          disabled={this.state.disabled}
                          value={this.state.subLocation}
                          className="nice-select" name="subLocation" >
                              <option value="null">Sub Location</option>
                              <option value="mtPleasant">Mt, Pleasant</option>
                              <option>Greencroft</option>
                              <option>Budiriro</option>
                              <option>Madokero</option>
                              <option>Zimre Park</option>
                              <option>Ruwa</option>
                              <option>Kuwadzana</option>
                          </select>
                      </div>
                      <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-ring col-lg-12 col-md-12">
                          <select 
                          disabled={this.state.disabled}
                          value={this.state.bedrooms}
                          className="nice-select" name="bedrooms" >
                              <option value="null">Bedrooms</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                          </select>
                      </div>
                      <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-12 col-md-12">
                          <div className="input-item input-item-name ltn__custom-icon">
                              <input
                                  type="text"
                                  value={this.state.minSize}
                                   disabled={this.state.disabled}
                                  name="minSize"
                                  placeholder="Min size (in sqft)"
                              />
                          </div>
                      </div>
                      <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-12 col-md-12">
                          <div className="input-item input-item-name ltn__custom-icon">
                              <input
                                  type="text"
                                   value={this.state.maxSize}
                                   disabled={this.state.disabled}
                                  name="maxSize"
                                  placeholder="Max size (in sqft)"
                              />
                          </div>
                      </div>
                      <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-12 col-md-12">
                          <div className="input-item input-item-name ltn__custom-icon">
                              <textarea
                                  type="textarea"
                                  value={this.state.moreInformation}
                                   disabled={this.state.disabled}
                                  name="moreInformation"
                                  placeholder="More information"
                              />
                          </div>
                      </div>
                      <div className="car-price-filter-range col-lg-12">
                          <div className="price_filter">
                              <div className="price_slider_amount">
                                  <p>Property Price Range (USD)*</p>
                                  <input
                                      type="text"
                                      disabled={this.state.disabled}
                                      className="amount"
                                      name="price"
                                      placeholder="Add Your Price"
                                  />
                              </div>
                              <div className="slider-range" />
                          </div>
                      </div>
                      <div className="mini-cart-footer">
                        <div className="btn-wrapper go-top">
                          <button type="submit" className="theme-btn-1 btn btn-effect-1">
                            Submit
                          </button>
                          <div  className="theme-btn-2 btn btn-effect-2 ltn__utilize-close">
                            Cancel
                          </div>
                        </div>
                      </div>
                  </form>
              </div>
            
          </div>
                </div>
      </div>
    );
  }
}

export default AboutV1;
