import React from "react";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";

function Property({
    id,
    name,
    location,
    price,
    bathrooms,
    bedrooms,
    city,
    subLocation,
    propertyType,
    rooms,
    description,
    images,
    deposit,
    propertyID,
    x,
    y,
    propertyStatus,
    size,
    isTsigiroTrustFund,
    view,
}) {
    let publicUrl = process.env.PUBLIC_URL + "/";
    function currencyFormat(num) {
        return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
    return (
        <div 
         className="col-xl-6 col-sm-6 col-12">
            <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                <div className="product-img go-top">
                    <a
                        href={"/property-details?" + propertyID}
                    >
                        <img
                            style={
                                {
                                    maxHeight: "70vh",
                                    minHeight: "70vh",   
                                    minWidth : "100%",
                                    maxWidth : "100%",                                 
                                    overflow: "hidden"
                                }
                            }
                         src={process.env.REACT_APP_SPACES_URL + images[0]} alt="#" />
                    </a>
                </div>
                <div className="product-info">
                    <div className="product-badge">
                        <ul>
                            <li className="sale-badg">{propertyStatus || "For Sale"}</li>
                        </ul>
                        {isTsigiroTrustFund && <ul>
                            <li
                                className="sale-badg"
                                style={{fontSize: "11px", color: "orange"}}
                            >
                                <i className="fas fa-star" /> Included In Tsigiro Trust Fund
                            </li>
                        </ul>}
                    </div>
                    <h2 className="product-title go-top">{name}</h2>
                    <div className="product-img-location go-top">
                        <ul>
                            <li>
                                <i className="flaticon-pin" /> {location} {city?.name} {subLocation?.name}
                            </li>
                        </ul>
                    </div>
                    <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                        {
                            bedrooms &&<li>
                                <span>{bedrooms} </span>
                                Bed
                            </li>
                        }
                        {
                            bathrooms &&
                            <li>
                                <span>{bathrooms} </span>
                                Bath 
                            </li>
                        }
                        
                        <li>
                            <span>{size} </span>
                            Square Meters
                        </li>
                    </ul>


                    <a
                        href={"/property-details?" + propertyID}
                    >
                        <Button style={{backgroundColor: "#47878a", marginTop: "10px", marginBottom: "20px"}}>
                            View Property
                        </Button>
                    </a>

                </div>
                <div className="product-info-bottom" style={{alignItems: 'center', justifyContent: 'space-between', display: 'flex'}}>
                    <div className="product-price">
                        { price && <span>USD {currencyFormat(price)}</span>}
                    </div>
                    <div style={{marginBottom: 7, display: 'flex'}}>
                        <i className="icon-whatsapp col-md-1" style={{color: 'green', fontSize: 15, cursor: 'pointer'}}></i>
                        <i className="icon-mail col-md-1" style={{color: 'green', fontSize: 15, cursor: 'pointer'}}></i>
                        <i className="icon-phone-call col-md-1" style={{color: 'green', fontSize: 15, cursor: 'pointer'}}></i>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Property;
