import React, {Component} from "react";
import ReactDOM from "react-dom";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    MemoryRouter,
} from "react-router-dom";
import HomeV1 from "./components/home-v1";
import Service from "./components/service";
import ProdductDetails from "./components/product-details";
import PropertyDetails from "./components/property-details";
import PropertiesForSale from "./components/for-sale";
import PropertiesForInvesting from "./components/for-investing";
import {store} from "./redux/store";
import {Provider} from "react-redux";
import InvestmentDetails from "./components/shop-components/investment-details";
import PropertyInquiry from "./components/functional-components/PropertyInquiry";
import PrivateRoute from "./PrivateRoute";
import {QueryClient, QueryClientProvider} from 'react-query'
import PropertyRequests from "./components/functional-components/PropertyRequests";
import PropertyPurchases from "./components/functional-components/PropertyPurchases";
import PropertyInvestments from "./components/functional-components/PropertyInvestments";
import '../src/assets/icons/_dripicons.scss';

class Root extends Component {
    render() {
        return (
            <Provider store={store}>
                <Router>
                    <div>
                        <Switch>
                            <PrivateRoute>
                                <Route exact path="/" component={HomeV1} />
                                <Route path="/property-request-success" component={Service} />
                                <Route exact path="/properties" component={PropertiesForSale} />
                                <Route path="/investing" component={PropertiesForInvesting} />
                                <Route path="/product-details" component={ProdductDetails} />
                                <Route path="/property-details" component={PropertyDetails} />
                                <Route path="/investment-details" component={InvestmentDetails} />
                                <Route path="/property-inquiry" component={PropertyInquiry} />
                                <Route path="/property-requests" component={PropertyRequests} />
                                <Route path="/property-purchases" component={PropertyPurchases} />
                                <Route path="/property-investments" component={PropertyInvestments} />

                            </PrivateRoute>
                        </Switch>
                    </div>
                </Router>
            </Provider>
        );
    }
};

const queryClient = new QueryClient()


export default function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <Root />
        </QueryClientProvider>
    )
}

ReactDOM.render(<App />, document.getElementById("quarter"));
