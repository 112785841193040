import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';


const FileUploader2 = (props) => {
    const [selectedFiles, setSelectedFiles] = useState([]);

    /**
     * Handled the accepted files and shows the preview
     */
    const handleAcceptedFiles = (files) => {
        var allFiles = files;

        if (props.showPreview) {
            (files || []).map((file) =>
                Object.assign(file, {
                    preview: file['type'].split('/')[0] === 'image' ? URL.createObjectURL(file) : null,
                    formattedSize: formatBytes(file.size,2),
                })
            );
            allFiles = [...selectedFiles];
            allFiles.push(...files);
            setSelectedFiles(allFiles);
        }

        if (props.onFileUpload) props.onFileUpload(allFiles);
    };

    /**
     * Formats the size
     */
    const formatBytes = (bytes, decimals) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    /*
     * Removes the selected file
     */
    const removeFile = (e, fileIndex) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(fileIndex, 1);
        setSelectedFiles(newFiles);
        if (props.onFileUpload) props.onFileUpload(newFiles);
    };

    return (
        <div>
            <Dropzone {...props} onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                    <div
                        className={classNames('dropzone', 'dz-clickable',{
                            // 'dz-started': selectedFiles && selectedFiles.length > 0,
                        })}
                        style={{marginBottom:20,borderStyle:'dashed',borderRadius:10,border:'1px dotted #e0e0e0'}}
                        >
                        <div {...getRootProps()}
                        >
                            <div className="dz-message needsclick" style={{padding:10}}>
                                <input {...getInputProps()} />
                                <i className="h1 text-muted uil-cloud-upload"></i>
                                <h3 style={{fontSize:14}}>Drop files here or click to upload.</h3>
                                <span style={{fontSize:14}}>
                                    {props.title}
                                </span>
                            </div>
                            {props.showPreview &&
                                (selectedFiles || []).map((f, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            {f.preview && (
                                                <div
                                                    onClick={(e) => e.stopPropagation()}
                                                    className="dz-preview dz-processing dz-error dz-complete dz-image-preview">
                                                    <div className="dz-image">
                                                        <img
                                                            style={{}}
                                                            key={i}
                                                            data-dz-thumbnail=""
                                                            alt={f.name}
                                                            src={f.preview}
                                                        />
                                                    </div>

                                                    <div className="dz-details">
                                                        <div className="dz-size">
                                                            <span data-dz-size="">
                                                                <strong>{f.formattedSize}</strong> KB
                                                            </span>
                                                        </div>
                                                        <div className="dz-filename">
                                                            <span data-dz-name="">{f.name}</span>
                                                        </div>
                                                    </div>

                                                    <div className="dz-remove">
                                                        <button onClick={(e) => removeFile(e, i)} style={{border:'none',color:'red',backgroundColor:'transparent'}}>
                                                                    x
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                            {!f.preview && (
                                                <div
                                                    onClick={(e) => e.stopPropagation()}
                                                    className="dz-preview dz-file-preview dz-processing dz-error dz-complete">
                                                    <div className="dz-image">
                                                        <img data-dz-thumbnail="" alt="" />
                                                    </div>
                                                    <div className="dz-details">
                                                        <div className="dz-size">
                                                            <span data-dz-size="">
                                                                <strong>{f.formattedSize}</strong> KB
                                                            </span>
                                                        </div>
                                                        <div className="dz-filename">
                                                            <span data-dz-name="">{f.name}</span>
                                                        </div>
                                                    </div>
                                                    <div className="dz-remove">
                                                         <button onClick={(e) => removeFile(e, i)} style={{border:'none',color:'red',backgroundColor:'transparent'}}>
                                                                    x
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                        </div>
                    </div>
                )}
            </Dropzone>
        </div>
    );
};

FileUploader2.defaultProps = {
    showPreview: true,
};

export default FileUploader2;
