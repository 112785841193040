import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV5 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'

        return <div className="ltn__about-us-area pb-115 go-top" style={{marginTop: 80}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 align-self-center">
                        <div className="about-us-img-wrap ltn__img-shape-left  about-img-left">
                            <img src={publicUrl + "assets/img/tsigiro/prop1.jpg"} alt="Image" />
                        </div>
                    </div>
                    <div className="col-lg-7 align-self-center">
                        <div className="about-us-info-wrap">
                            <div className="section-title-area ltn__section-title-2--- mb-20">
                                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Property Request</h6>
                                <h1 className="section-title">We've Received Your Request and our agents are on it
                                    <span>.</span></h1>
                                <p>We have plenty of agents working for us accross the country to ensure we get the exact property type
                                    you are looking for.
                                </p>
                            </div>
                            <div className="about-us-info-wrap-inner about-us-info-devide---">
                                <p>Be rest assured we that we'll act promptly to process your request, thank you for trusting us. </p>
                            </div>
                            <div className="btn-wrapper animated">
                                <Link to="/properties" className="theme-btn-1 btn btn-effect-1 text-uppercase">Browse Properties</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default AboutV5