import ApiClient from "./Client";
import { getLoggedInUser } from './Cookie';

const version = '/api/v1';

const requestParams = {
  headers: {
    'x-access-token': getLoggedInUser()?.token
  }
};

class AccountsService {

  getUserSuspenseAccount = (country) => ApiClient().get(`${version}/accounts/my/suspense/${country}`,requestParams);

  initiateInvestment = (data) =>  ApiClient().post(`${version}/properties/investment/new`,data , requestParams);

  userInvestment =(propertyId)=> ApiClient().get(`${version}/properties/investment/user/${propertyId}`, requestParams);

  depositToSuspenseAccount =(data)=> ApiClient().post(`${version}/accounts/depositToSuspense`, data, {
    headers:{
        ...requestParams.headers,
        "Content-Type": "multipart/form-data"
    }
  });

  makePayment =(x)=> {
        document.makeP.method = "POST";
        document.makeP.action =' https://paynow.netcash.co.za/site/paynow.aspx';
        document.makeP.submit();
 };


}


export default new AccountsService();
