import React, {useRef} from "react";
import { Link } from "react-router-dom";
import Navbar from "../global-components/navbar";
import Footer_v1 from "../global-components/footer";
import { useState, useEffect } from "react";
import {
  NotificationContainer,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Table from 'rc-table';
import PropertyService from "../../api/PropertyService";
import { useQuery,useMutation } from "react-query";
import RequestService from "../../api/RequestService";
import { Modal, Form, Input, Button, Select, Typography  } from 'antd';
import TextArea from "antd/es/input/TextArea";
import Page_header from "../global-components/page-header";

const PropertyRequests = () => {
  const [visible, setVisible] = useState(false);
  const [disable, setDisabled] = useState(true)
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [modalData, setModalData] = useState({});
  const [state, setState] = useState({
        propertyTypes: [],
        propertyCities: [],
        propertySuburbs: [],
        userRequests: [],
  });
  const [propertyType, setPropertyType] = useState(modalData?.propertyType?.name);
  const [city, setCity] = useState(modalData?.city?.name);
  const [subLocation, setSubLocation] = useState(modalData?.subLocation?.name);
  const [bedrooms, setBedrooms] = useState(modalData?.bedrooms);
  const [minArea, setMinArea] = useState(modalData?.minArea);
  const [maxArea, setMaxArea] = useState(modalData?.maxArea);
  const [moreInformation, setMoreInformation] = useState(modalData?.moreInformation);
  const [status, setStatus] = useState("true")
  const [userId, setUserId] = useState("");
  const [requestDataSubmission, setRequesttData] = useState({});
  const [appartment, setAppartment] = useState();
  const [surbub, setSurbub] = useState();
  

  const ref = useRef(null);
  const surburbRef = useRef(null);

  
  const newRequest = useMutation(RequestService.create, {
    onSettled: ((x) => {
        ref.current.complete();
    }),
    onSuccess: ((x) => {
        setState({submitRequest: true});
    }),
    onError: ((x) => {
        console.log(x);
    })
})

const handleRequstSubmission = (key) => {

  key.preventDefault();

  const values = {...requestData};

  if (city) {
      values.city = city;
  }
  if (appartment) {
      values.propertyType = appartment;
  }
  if (surbub) {
      values.subLocation = surbub;
  }

  newRequest.mutate(values);


}

const onRequestChange = (e) => {
  setRequesttData({...requestDataSubmission, [e.target.name]: e.target.value});
}




  const handlePropertyTypeChange = (value) => {
    const selectedPropertyType = state.propertyTypes.find((type) => type.name === value);
    setPropertyType(selectedPropertyType._id);
  };



  const handleFormCityChange = (value) => {
    const selectedCityType = state.propertyCities.find((type)=> type.name === value);
    setCity(selectedCityType._id);
  };

  const handleSubLocationChange = (value) => {
    const selectedSublocationType = state.propertySuburbs.find((type)=> type.name === value);
    setSubLocation(selectedSublocationType._id);
  };

  const handleBedroomsChange = (e) => {
    setBedrooms(e.target.value);
  };

  const handleMinAreaChange = (e) => {
    setMinArea(e.target.value);
  };

  const handleMaxAreaChange = (e) => {
    setMaxArea(e.target.value);
  };

  const handleMoreInformationChange = (e) => {
    setMoreInformation(e.target.value);
  }



    const { data: propertyTypesData} =
    useQuery("propertyTypes", PropertyService.getPropertyTypes);

  const { data: propertyCitiesData} =
    useQuery("propertyCities", PropertyService.getPropertyCity);
    
    const {data: requestData} = 
    useQuery("requestData", RequestService.getPropertyRequests);


    const deleteRequest = useMutation(RequestService.deletePropertyRequests);          
    const updateRequest = useMutation((data) => RequestService.updatePropertyRequests(data));
    // const propertyRequest = useMutation((data) => RequestService.create(data));
    
    const handleUpdateRequest = (id) => {
      const data = {
        status: status,
        propertyType: propertyType,
        city: city,
        subLocation: subLocation,
        bedrooms: bedrooms,
        minArea: minArea,
        maxArea: maxArea,
        moreInformation: moreInformation,
        user: userId,
        id
      };
      
      for (let key in data) {
        if (!data[key]) {
          delete data[key];
        }
      }
      updateRequest.mutate(data)
      setDisabled(true)
      setVisible(false)
    }

    const showModal = (data) => {
      setUserId(data?.user?._id)
      setVisible(true);
      setModalData(data);
    }
  
    const hideModal = () => {
      setVisible(false);
      setDeleteVisible(false)
      setDisabled(true)
    };

    const showDeleteModal = (data) => {
      setDeleteVisible(true)
      setModalData(data);
    }

   const handleDeleteRequest = (id) => {
    deleteRequest.mutate(id);
    setDeleteVisible(false)
    }




 // When propertyTypesData or propertyCitiesData changes, update the state
  useEffect(() => {
    if (propertyTypesData) {
      setState((prevState) => ({
        ...prevState,
        propertyTypes: propertyTypesData.PropertyTypes,
      }));
    }
    if (propertyCitiesData) {
      setState((prevState) => ({
        ...prevState,
        propertyCities: propertyCitiesData.PropertyCities,
      }));
    }

    if  (requestData) {
      setState((prevState) => ({
        ...prevState,
        userRequests: requestData.propertyRequests,
      }));
    }
  }, [propertyTypesData, propertyCitiesData, requestData]);

  useEffect(() => {
    if (state?.propertyCities?.length) {
      const suburbs = state?.propertyCities.reduce((acc, curr) => {
        const currSuburbs = curr.suburbs.map((suburb) => ({
          _id: suburb._id,
          name: suburb.name,
        }));
        acc.push(...currSuburbs);
        return acc;
      }, []);
      setState((prevState) => ({
        ...prevState,
        propertySuburbs: suburbs,
      }));
    }
  }, [state.propertyCities]);

    const columns = [
      {
        title: '#',
        dataIndex: 'rowNumber',
        key: 'rowNumber',
        render: (text, record, index) => index + 1,
        width: 50,
      },
      {
        title: 'ID',
        dataIndex: '_id',
        key: 'Request ID',
        width: 300,
      },
      {
        title: 'Type',
        dataIndex: 'propertyType',
        key: 'propertyType',
        render: ((x)=>{
          return x?.name
        }),
        width: 200,
      },
      {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
        render: ((x)=>{
          return x?.name
        }),
        width: 200,
      }, 
      {
        title: 'Sub Location',
        dataIndex: 'subLocation',
        key: 'sub-location',
        render: ((x)=>{
          return x?.name
        }),
        width: 200,
      },
      {
        title: '',
        dataIndex: '',
        key: 'operations',
        render: (x) =>  <div className="col-lg-12" style={{display:'flex'}}>       
        <i className="fa fa-eye" style={{marginLeft:10,cursor:'pointer'}} onClick={() => showModal(x)}></i>              
        <i className="fa fa-trash" style={{marginLeft:10,cursor:'pointer'}} onClick={()=> showDeleteModal(x)}></i>
        
      </div>
        
      },
    ];
    
    const data = state?.userRequests?.map((request, index) => {
      return { ...request, rowNumber: index };
    });

    const propertyTypeOptions = propertyTypesData?.PropertyTypes.map((propertyType) => ({
      value: propertyType.name,
      label: propertyType.name,
    }));

    const propertyCityOptions = propertyCitiesData?.PropertyCities.map((city) => ({
      value: city.name,
      label: city.name,
    }));


    const subLocationOptions = state?.propertySuburbs?.map((sub) => ({
      value: sub.name,
      label: sub.name,
    }));

    const handleEdit = () => {
      setDisabled(false)
    }    

    const handleCityChange = (event) => {
      setSurbub(null)
      setCity(event);
      // surburbRef.current.;
      const selectedCity = event;
      const suburbs = (propertyCitiesData?.PropertyCities || [])
          .filter(city => city._id === selectedCity)
          .map((x) => x.suburbs)[0];
      setState({...state, propertySuburbs: suburbs});
  };

    const handleSuburbChange = (e) => {
      setSurbub(e);
  }

  const handleApprtmentChange = (e) => {
      setAppartment(e);
  }


    return (
      <div className="">
        <Navbar />
        <Page_header headertitle="My Property Purchases" />
        <div className="ltn__about-us-area pt-120 pb-90 " style={{paddingTop:0}}>
			<div className="ltn__modal-area ltn__add-to-cart-modal-area">
			</div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color" style={{fontWeight:400,color:'#000'}}>
                    Manage Your Property Requests
                  </h6>
                    <Table columns={columns} data={data}
                      emptyText="No requests submitted"
                      />
                      <Modal
                      visible={visible}
                      title={`RequestID: ${modalData._id}`}
                      onOk={hideModal}
                      onCancel={hideModal}
                      footer={null}
                    >
           <Form style={{display: "flex", flexDirection: "column"}}>
      <Form.Item label="Property Type" style={{width: "100%"}}>
        <Select
          defaultValue={modalData?.propertyType?.name}
          options={propertyTypeOptions}
          size="large"
          disabled={disable}
          onChange={handlePropertyTypeChange}
          style={{width: "100%"}}
        />
      </Form.Item>
      <Form.Item label="City Location" style={{width: "100%"}}>
        <Select
          defaultValue={modalData?.city?.name}
          options={propertyCityOptions}
          size="large"
          disabled={disable}
          onChange={handleFormCityChange}
          style={{width: "100%"}}
        />
      </Form.Item>
      <Form.Item label="Suburb Location" style={{width: "100%"}}>
        <Select
          defaultValue={modalData?.subLocation?.name}
          options={subLocationOptions}
          size="large"
          disabled={disable}
          onChange={handleSubLocationChange}
          style={{width: "100%"}}
        />
      </Form.Item>
      <Form.Item label="Bedrooms" style={{width: "100%"}}>
        <Input
          name="bedrooms"
          defaultValue={modalData?.bedrooms}
          size="large"
          disabled={disable}
          onChange={handleBedroomsChange}
          style={{width: "100%"}}
        />
      </Form.Item>
      <Form.Item label="Minimum Area (sqm)" style={{width: "100%"}}>
        <Input
          name="minArea"
          defaultValue={modalData?.minArea}
          size="large"
          disabled={disable}
          onChange={handleMinAreaChange}
          style={{width: "100%"}}
        />
      </Form.Item>
      <Form.Item label="Maximum Area (sqm)" style={{width: "100%"}}>
        <Input
          name="maxArea"
          defaultValue={modalData?.maxArea}
          size="large"
          disabled={disable}
          onChange={handleMaxAreaChange}
          style={{width: "100%"}}
        />
      </Form.Item>
      <Form.Item label="More Information (optional)" style={{width: "100%"}}>
        <TextArea
          name="moreInformation"
          defaultValue={modalData?.moreInformation}
          size="large"
          disabled={disable}
          onChange={handleMoreInformationChange}
          style={{width: "100%"}}
        />
      </Form.Item>
      <div className="modal-buttons" style={{display: "flex", justifyContent: "flex-end"}}>
        <Button type="primary" onClick={handleEdit} style={{marginRight: "10px"}}>
          Edit Request
        </Button>{" "}
    <Button type="success" style={{backgroundColor: "#133236", color: "white", marginRight: "10px"}} onClick={(e) => handleUpdateRequest(modalData._id, e)}>
      Save Request
    </Button>{" "}
    <Button type="danger" style={{backgroundColor: "red", color: "white"}} onClick={hideModal}>
      Cancel
    </Button>
  </div>
</Form>


                    </Modal>

                    <Modal
                      visible={deleteVisible}
                      // title={`Property Request ID: ${modalData._id}`}
                      onOk={hideModal}
                      onCancel={hideModal}
                      footer={null}
                     
                    >
                      <Typography.Title level={3} style={{ textAlign: 'center, color: "#133236"' }}>Delete Property Request</Typography.Title>
                      <Typography.Paragraph style={{color: "#133236"}}>Are you sure you want to delete property request with request id {modalData._id}? This action cannot be undone.</Typography.Paragraph>

                      <Form>
                        <Button type="danger" style={{backgroundColor: "#133236", color: "white"}} onClick={() => handleDeleteRequest(modalData._id)}>Delete</Button>
                        {" "}
                        <Button type="default" style={{backgroundColor: "grey", color: "white"}} onClick={hideModal}>Cancel</Button>
                      </Form>
                    </Modal>
                </div>
                <div className="ltn__callout bg-overlay-theme-05  mt-30">
                  <p>
                    We're making property ownership easy and inclusive, for the first
                    time ever, you can submit property requests on where you would love to own a property or stand and we'll help you find what you need in no time
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* submit new request */}
       <div
          id="ltn__utilize-cart-menu"
          className="ltn__utilize ltn__utilize-cart-menu"
        >
          <div className="ltn__utilize-menu-inner ltn__scrollbar">
            <div className="ltn__utilize-menu-head">
              <span className="ltn__utilize-menu-title">{state?.requestStatus}</span>
              <button className="ltn__utilize-close">×</button>
            </div>
             <div className="car-dealer-form-inner">
             <form
            // action='/properties'
            className="ltn__car-dealer-form-box row"
            id='buy_form'
            onSubmit={handleRequstSubmission}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar" style={{ marginBottom: 20 }}>
                <Select
                    placeholder="Select Property Type"
                    allowClear
                    size='large'
                    style={{ minWidth: 200, width: '100%', fontSize: 16 }}
                    onChange={handleApprtmentChange}
                    options={(propertyTypesData?.PropertyTypes || []).map((x) => {
                        return {
                            label: x.name,
                            value: x._id
                        }
                    })}
                />
            </div>

    <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter" style={{ marginBottom: 20 }}>
        <Select
            placeholder="Select City"
            size='large'
            style={{ minWidth: 200, width: '100%', fontSize: 16 }}
            onChange={handleCityChange}
            options={(propertyCitiesData?.PropertyCities || []).map((x) => {
                return {
                    label: x.name,
                    value: x._id
                }
            })}
        />
    </div>

    <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-ring" style={{ marginBottom: 20 }}>
        <Select
            ref={surburbRef}
            placeholder="Select a surbub"
            size='large'
            style={{ minWidth: 200, width: '100%', fontSize: 16 }}
            value={surbub}
            onChange={handleSuburbChange}
            options={(state?.propertySuburbs || []).map((x) => {
                return {
                    label: x.name,
                    value: x._id
                }
            })}
        />
    </div>
    <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog" style={{ marginBottom: 20 }}>
        <Input
            type='number'
            size='large'
            name="bedrooms"
            max={6}
            min={0}
            placeholder='Enter Bedrooms'
            onChange={onRequestChange}
        />
    </div>

    <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog" style={{ marginBottom: 20 }}>
        <div className="input-item input-item-name ltn__custom-icon">
            <Input
                type='number'
                size='large'
                name="minArea"
                min={0}
                placeholder='Minimum size in square meters'
                onChange={onRequestChange}
                style={{ minWidth: 200, width: '100%', fontSize: 16 }}
            />
        </div>
    </div>

        <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog" style={{ marginBottom: 20 }}>
            <div className="input-item input-item-name ltn__custom-icon">
                <Input
                    type='number'
                    size='large'
                    min={0}
                    name="maxArea"
                    placeholder='Maximum size in square meters'
                    onChange={onRequestChange}
                    style={{ minWidth: 200, width: '100%', fontSize: 16 }}
                />
            </div>
        </div>
        <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-6 col-md-6" style={{ marginBottom: '20px' }}>
        <div className="input-item input-item-name ltn__custom-icon">
            <Input
                type='number'
                size='large'
                min={0}
                name="minPrice"
                    placeholder='Minimum Property Price in USD'
                    onChange={onRequestChange}
                    style={{ minWidth: 200, width: '100%', fontSize: 16 }}
                />
            </div>
        </div>
        <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-6 col-md-6" style={{ marginBottom: '20px' }}>
            <div className="input-item input-item-name ltn__custom-icon">
                <Input
                    type='number'
                    size='large'
                    name="maxPrice"
                    placeholder='Maximum Property Price in USD'
                    onChange={onRequestChange}
                    min={0}
                    style={{ minWidth: 200, width: '100%', fontSize: 16 }}
                />
            </div>
        </div>
        <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-6 col-md-6" style={{ marginBottom: '20px' }}>
            <div className="input-item input-item-name ltn__custom-icon">
                <Input.TextArea
                    size='large'
                    name="moreInformation"
                    rows={4}
                    placeholder='More Information'
                    onChange={onRequestChange}
                    style={{ minWidth: 200, width: '100%', fontSize: 16 }}
                />
            </div>
        </div>

            {/* <div className="car-price-filter-range col-lg-12">
                <div className="btn-wrapper text-center go-top">
                    <button type="submit" className="btn theme-btn-1 btn-effect-1 text-uppercase">Submit Request</button>
                </div>
            </div> */}
            
                </form>
              </div>
            
          </div>
                </div>
      </div>
      <NotificationContainer/>
      <Footer_v1 />
    </div>
  );
};

export default PropertyRequests;
