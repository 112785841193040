import React from "react";
import Spinner from "react-bootstrap/Spinner";

export default function LoadingBox() {
  return (
    <Spinner animation="border" role="status" style={{marginTop:12}}>
       <span className="visually-hidden">Loading</span>
    </Spinner>
  );
}
