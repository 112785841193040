import React,{ useRef, useState } from 'react';
import {useMutation} from 'react-query';
import LoadingBar from 'react-top-loading-bar';
import {
    NotificationManager,
} from "react-notifications";
import {getDecodedUser, getEntityCountry, getWalletId} from '../../api/Cookie';
import FileUploader2 from './FileUploader2';
import Input from './Input';
import AccountsService from '../../api/AccountsService';

const FundTransfer = (props) => {
    const [state,setState] = useState([]);
    const walletId = getWalletId(getEntityCountry());
    const country = getEntityCountry();
    const owner = getDecodedUser().id;
    const ref = useRef(null);

    const { mutate , isLoading } = useMutation( AccountsService.depositToSuspenseAccount, {
        onSettled:()=>{
            ref.current.complete();
            props.handleClose();
        },
        onSuccess:()=>{
        NotificationManager.success(
            <h1 style={{color: '#fff', fontSize: 13}}>{`Funds will reflect soon, once they have been approved`}</h1>,
            <p style={{color: '#fff', fontSize: 12}}>Request Received Succesfully</p>,
        );
        },
        onError:((x)=>{
            console.log(x);
        })
    });

    const handleChange =(e)=>{
        setState({...state,[e.target.name]:e.target.value});
    };

    const depositToAccount =()=>{
        const newTransfer = {...state};
        newTransfer.amount = parseFloat(newTransfer.amount);
        newTransfer.owner = owner;
        newTransfer.transactionType = "Deposit";
        newTransfer.feeType = "EFTDEPOSIT";
        newTransfer.to = walletId;
        newTransfer.country = country;
        var formData = new FormData();
        (newTransfer.files || []).map((file)=>{
            formData.append(`supportingDocuments`, file);
        });
        
        for(var key in newTransfer){
        formData.append(key,newTransfer[key]);
        };
        
        ref.current.continuousStart();

        mutate(formData);
    };

   return (
    <div>
        <LoadingBar ref={ref} progress={5} />
        <p style={{fontSize:12,marginBottom:10,color:'#3b3b3b'}}>
            Funds may take a while to reflect in your account as we verify your transfer.
        </p>
        <p style={{marginBottom:10,color:'#3b3b3b',fontSize:13}}>Banking Details</p>
        <ul style={{marginBottom:10}}>
            <li style={{color:'#3b3b3b',fontSize:13}}>
                Bank: First National Bank (FNB)
            </li>
            <li style={{color:'#3b3b3b',fontSize:13}}>
                Account Name: Tsigiro Usekelo (Pty) Ltd
            </li>
            <li style={{color:'#3b3b3b',fontSize:13}}>
                Account Number: 63043218436
            </li>
            <li style={{color:'#3b3b3b',fontSize:13}}>
                Branch Code: 210835
            </li>
            <li style={{color:'#3b3b3b',fontSize:13}}>
                Swift Code: FIRNZAJJ
            </li>
        </ul>
            <Input
            type="number"
            placeHolder="0.00"
            min={0}
            name="amount"
            onChange={handleChange}
            edit
            label="Enter Amount (ZAR)"
            />
            <Input
            type="textarea"
            placeHolder="add any comments about your transaction here"
            min={0}
            edit
            name="clientComment"
            onChange={handleChange}
            label="Comment"
            />
            <div style={{width:'410px'}}>
            <FileUploader2
            title="Upload proof of payment"
            onFileUpload={(x)=>setState({...state,files:x})}
            />
            <button 
            disabled={isLoading}
            onClick={depositToAccount}
            className='btn btn-primary'>
                Pay now
            </button>
       </div>
    </div>
  );

}

export default FundTransfer;