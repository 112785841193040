import React from "react";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import {getEntityCountry, getGlobalSettings} from "../../api/Cookie";

function Investments({
    id,
    name,
    description,
    location,
    price,
    fundingTarget,
    title,
    interest,
    rent,
    marketValuation,
    discount,
    currentInvestment,
    city,
    subLocation,
    estimatedNetRentalYield,
    bathrooms,
    totalRaised,
    bedrooms,
    rooms,
    area,
    type,
    images,
    propertyID,
    x,
    y,
    propertyStatus,
    size,
    share,
}) {

    let publicUrl = process.env.PUBLIC_URL + "/";

    const percentage = Math.floor((totalRaised / fundingTarget) * 100);

    const country = getEntityCountry();
    const rateToUSD = getGlobalSettings().USDTOZARRATE

    function currencyFormat(num) {
        if (!num) {
            num = 0;
        }
        const moneyString = country === "Zimbabwe" ? "$" : "ZAR";
        if (country !== "Zimbabwe") {
            num = num * rateToUSD;
        }

        return moneyString + " " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
    return (
        <div className="col-lg-6 col-xl-6 col-sm-6 col-12">
            <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                <div className="product-img go-top">
                    <a
                        href={"/investment-details?" + id}
                    >
                        <img src={process.env.REACT_APP_SPACES_URL + images[0]} alt="#" />
                    </a>
                </div>
                <div className="product-info">
                    <h2 className="product-title go-top">{title}</h2>
                    <div className="product-img-location go-top">
                        <ul>
                            <li>
                                <i className="flaticon-pin" /> {city?.name}, {subLocation?.name}
                            </li>
                        </ul>
                    </div>
                    <ProgressBar
                        variant="success"
                        now={percentage}
                        label={`${percentage}%`}
                        style={{marginTop: "20px", marginBottom: "20px"}}
                    />

                    <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                        <li>
                            Funding Target: <span> {currencyFormat(fundingTarget || 0)}</span>
                        </li>
                        <li>
                            Raised:{" "}
                            <span>{currencyFormat(totalRaised || 0)}</span>
                        </li>

                        {/* <li>
                            Interest Rate Of Return (RR): <span>{interest} %</span>
                        </li> */}
                        {/* <li>
                            Est Net Rental Yield: <span>{estimatedNetRentalYield} %</span>
                        </li> */}
                        <li>
                            Market Valuation:{" "}
                            <span>{currencyFormat(marketValuation || 0)}</span>
                        </li>
                        <li>
                            Current Share Price:{" "}
                            <span> {currencyFormat(share)} per share</span>
                        </li>
                        {/* <li>
                            Tsigiro Discount: <span>{discount} %</span>
                        </li> */}
                    </ul>
                    <a href={"/investment-details?" + id}
                    >
                        <Button
                            style={{backgroundColor: "#47878a", marginBottom: "10px"}}
                        >
                            View Project
                        </Button>
                    </a>
                </div>
                <div className="product-info-bottom"></div>
            </div>
        </div>
    );
}

export default Investments;
