import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {Tooltip as ReactTooltip} from "react-tooltip";

const Input = (props) => {

    if (props.type === "textarea") {
        return <div className='input-container' style={{width:'410px',marginTop:10}}>
            <label style={{alignSelf: 'flex-start'}}>{props.label}</label>
            <br></br>
            <textarea {...props} style={{padding: 10}}>
            </textarea>
        </div>
    }


    if (props.type === "select") {
        return <div className='input-container'>
            <label style={{alignSelf: 'flex-start', fontWeight: props.edit ? 400 : 600}}>{props.label}</label>
            <br></br>
            {props.edit ? <select {...props}>
                {props.children}
            </select> : <p>{props.fieldValue || "-"}</p>}
        </div>
    } else {
        return (
            <div className='input-container'>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: -13}}>
                    <label style={{alignSelf: 'flex-start', fontWeight: props.edit ? 400 : 600}}>{props.label}</label>
                    {props.error && <div style={{display: 'flex'}} >
                        <p className='error-message' style={{margin: 0}}>{props.error}</p>
                        <FontAwesomeIcon
                            id={props.id}
                            icon={faExclamationCircle} size="1x" style={{marginLeft: 10, color: 'red'}} />
                        {props.tooltip && <ReactTooltip
                            anchorId={props.id}
                            place="top"
                            content={props.tooltip}
                        />}
                    </div>
                    }
                </div>
                <br></br>
                {
                    props.edit ?
                        <input type={props.type} className="error" style={{border: props.error ? '1px solid red' : 'none'}} {...props} />
                        : <p>{props.type === "date" ?
                            props.defaultValue ? new Date(props.defaultValue).toLocaleDateString() : "-"
                            : props.fieldValue || "-"}</p>
                }
            </div>
        );
    }




}

export default Input