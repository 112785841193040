import React, {useRef, useEffect, useState} from 'react'
import {useMutation} from 'react-query';
import {Redirect} from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import RequestService from '../../api/RequestService';
import {fixPricing, objectToQuery, queryAdjuster} from '../../api/RouteHelpers';
import PropertyService from '../../api/PropertyService';
import {useQuery} from "react-query";
import {Input} from 'antd';
import {Select, Space} from 'antd';


const BannerMain = () => {
    const [state, setState] = useState({
        propertyTypes: [],
        propertyCities: [],
        propertySuburbs: [],
    });
    const [stateData, setStateData] = useState({});
    const [investmentData, setInvestmentData] = useState({});
    const [requestData, setRequesttData] = useState({});
    const [suburb, setSurbub] = useState();
    const [city, setCity] = useState();
    const [apartment, setAppartment] = useState();
    const [propertyQuestion, setPropertyQuestion] = useState();
    const [budgetRange, setBudgetRange] = useState();
    const [timelines, setTimelines] = useState();
    const [purpose, setPurpose] = useState();
    const [grossIncome, setGrossIncome] = useState();
    const [howMuch, setHowMuch] = useState();
    const [moneyUse, setMoneyUse] = useState();

    const handleBudgetRange = (e) => {
        setBudgetRange(e);
    }

    const handleHowMuch = (e) => {
        setHowMuch(e);
    }

    const handleMoneyUse = (e) => {
        setMoneyUse(e);
    }

    const handlePurposeChange = (e) => {
        setPurpose(e);
    }

    const handleGrossIncome = (e) => {
        setGrossIncome(e);
    }

    const handleTimelinesChange = (e) => {
        setTimelines(e);
    }

    const ref = useRef(null);
    const surburbRef = useRef(null);


    const {data: propertyTypesData, isLoading: isLoadingPropertyTypes} =
        useQuery("propertyTypes", PropertyService.getPropertyTypes);

    const {data: propertyCitiesData, isLoading: isLoadingPropertyCities} =
        useQuery("propertyCities", PropertyService.getPropertyCity, {
            onSuccess: ((x) => {
                const surbubs = x.PropertyCities.map((x) => {
                    return x.suburbs
                });
                setState({surbubs: surbubs});
            })
        });

    const newRequest = useMutation(RequestService.create, {
        onSettled: ((x) => {
            ref.current.complete();
        }),
        onSuccess: ((x) => {
            setState({submitRequest: true});
        }),
        onError: ((x) => {
            console.log(x);
        })
    })

    const onChange = (e) => {
        setStateData({...stateData, [e.target.name]: e.target.value});
    }

    const onInvestmentChange = (e) => {
        setInvestmentData({...investmentData, [e.target.name]: e.target.value});
    }

    const onRequestChange = (e) => {
        setRequesttData({...requestData, [e.target.name]: e.target.value});
    }

    const [suburbs , setSuburbs] = useState([])
    const [wish, setWish] = useState({});

    const onWishChange = (e) => {
        setWish({...wish, [e.target.name]: e.target.value});
    }

    const handleRequestSubmission = (key) => {

        key.preventDefault();
        console.log(wish);
        // const values = {...requestData};

        // if (city) {
        //     values.city = city;
        // }

        // if (apartment) {
        //     values.type = apartment;
        // }

        // if (suburb) {
        //     values.subLocation = suburb;
        // }

        // if (howMuch) {
        //     values.moneySentToZim = howMuch;
        // }

        // if (moneyUse) {
        //     values.useOfMoney = moneyUse;
        // }

        // console.log(values);
        // ref.current.continuousStart();
        newRequest.mutate(wish);

    }

    const handleSearch = (e) => {
        e.preventDefault();
        const formsearch = document.forms["request-form"];
        const formData = new FormData(formsearch);
        const propertyRequest = {};

    }

    const handleSuburbChange = (e) => {
        setSurbub(e);
    }

    const handleApprtmentChange = (e) => {
        setAppartment(e);
    }

    const handlePropertyQuestion = (e) => {
        setPropertyQuestion(e);
    }

    const handleCityChange = (event) => {
        setSurbub(null)
        setCity(event);
        // surburbRef.current.;
        const selectedCity = event;
        const suburbs = (propertyCitiesData?.PropertyCities || [])
            .filter(city => city._id === selectedCity)
            .map((x) => x.suburbs)[0];
        setState({...state, propertySuburbs: suburbs});
    };

    const handleSearchPropertiesSubmission = (e) => {
        e.preventDefault();
        const values = {...stateData};

        if (city) {
            values.city = city;
        }
        if (apartment) {
            values.propertyType = apartment;
        }
        if (suburb) {
            values.subLocation = suburb;
        }
        const query = objectToQuery(values);
        window.location.href = window.location.origin + "/properties?" + query.replace("&", "");
    }



    const handleSearchInvestmentSubmission = (e) => {
        e.preventDefault();
        const values = {...investmentData};

        if (city) {
            values.city = city;
        }
        if (apartment) {
            values.propertyType = apartment;
        }
        if (suburb) {
            values.subLocation = suburb;
        }
        const query = objectToQuery(values);

        window.location.href = window.location.origin + "/investing?" + query.replace("&", "");

    }

    if (isLoadingPropertyTypes || isLoadingPropertyCities) {
        // Render loading state
        return <div class="cv-spinner">
            <span class="spinner"></span>
        </div>
    };

    let publicUrl = process.env.PUBLIC_URL + '/';

    return (
        <div className="ltn__slider-area ltn__slider-4">
            {
                state.submitRequest && <Redirect to={{
                    pathname: '/property-request-success',
                }} />
            }
            <LoadingBar color='green' progress={5} ref={ref} />

            <div className="ltn__slide-one-active----- slick-slide-arrow-1----- slick-slide-dots-1----- arrow-white----- ltn__slide-animation-active">
                {/* ltn__slide-item */}
                <div
                    className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-4 bg-image bg-overlay-theme-black-60"
                    data-bs-bg={publicUrl + "/assets/img/tsigiro/houses.jpg"}
                    style={{backgroundImage: `url(${publicUrl}assets/img/tsigiro/houses.jpg)`}}
                >
                    <div className="ltn__slide-item-inner text-left">
                        <div className="container" style={{marginBottom: 10}}>
                            <div className="row">
                                <div className="col-lg-12 align-self-center">
                                    <div className="slide-item-car-dealer-form">
                                        <div className="ltn__car-dealer-form-tab">
                                            <div className="ltn__tab-menu  text-uppercase">
                                                <div className="nav">
                                                    <a
                                                        className="active show"
                                                        data-bs-toggle="tab"
                                                        href="#ltn__form_tab_1_1"
                                                        onClick={() => {
                                                            setSurbub(null);
                                                        }}
                                                    >
                                                        <i className="fas fa-cart-plus" />
                                                        Buy for myself
                                                    </a>
                                                    <a data-bs-toggle="tab" href="#ltn__form_tab_1_2"
                                                        onClick={() => {
                                                            setSurbub(null);
                                                        }}
                                                    >
                                                        <i className="fas fa-donate" />
                                                        Invest with others
                                                    </a>
                                                    <a data-bs-toggle="tab" href="#ltn__form_tab_1_3"
                                                        onClick={() => {
                                                            setSurbub(null);
                                                        }}
                                                    >
                                                        <i className="fas fa-vote-yea" />
                                                        Add To Wish List
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="tab-content">
                                                <div
                                                    className="tab-pane fade active show"
                                                    id="ltn__form_tab_1_1"
                                                >
                                                    <div className="car-dealer-form-inner"
                                                    >

                                                        <form
                                                            // action='/properties'
                                                            className="ltn__car-dealer-form-box row"
                                                            id='buy_form'
                                                            onSubmit={handleSearchPropertiesSubmission}
                                                        >
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-4 col-md-6">
                                                                <Select
                                                                    placeholder="Select Property Type"
                                                                    allowClear
                                                                    size='large'
                                                                    style={{
                                                                        minWidth: 200,
                                                                        width: '100%',
                                                                        fontSize: 16,
                                                                        fontFamily: 'Montserrat'
                                                                    }}
                                                                    onChange={handleApprtmentChange}
                                                                    options={(propertyTypesData?.PropertyTypes || []).map((x) => {
                                                                        return {
                                                                            label: x.name,
                                                                            value: x._id
                                                                        }
                                                                    })}
                                                                />
                                                            </div>

                                                            <div className=" col-lg-4 col-md-6">
                                                                <Select
                                                                    placeholder="Select City"
                                                                    size='large'
                                                                    style={{
                                                                        minWidth: 200,
                                                                        width: '100%',
                                                                        fontSize: 16,
                                                                    }}
                                                                    onChange={handleCityChange}
                                                                    options={(propertyCitiesData?.PropertyCities || []).map((x) => {
                                                                        return {
                                                                            label: x.name,
                                                                            value: x._id
                                                                        }
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-4 col-md-6">
                                                                <Select
                                                                    ref={surburbRef}
                                                                    placeholder="Select a surbub"
                                                                    size='large'
                                                                    style={{
                                                                        minWidth: 200,
                                                                        width: '100%',
                                                                        fontSize: 16,
                                                                    }}
                                                                    value={suburb}
                                                                    onChange={handleSuburbChange}
                                                                    options={(state?.propertySuburbs || []).map((x) => {
                                                                        return {
                                                                            label: x.name,
                                                                            value: x._id
                                                                        }
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-ring col-lg-4 col-md-6">
                                                                <Input
                                                                    type='number'
                                                                    size='large'
                                                                    name="bedrooms"
                                                                    max={10}
                                                                    min={0}
                                                                    placeholder='Enter Bedrooms'
                                                                    onChange={onChange}
                                                                />
                                                            </div>
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-4 col-md-6">
                                                                <div className="input-item input-item-name ltn__custom-icon">
                                                                    <Input
                                                                        type='number'
                                                                        size='large'
                                                                        name="minSize"
                                                                        placeholder='Minimum size in square meters'
                                                                        min ={0}
                                                                        onChange={onChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-4 col-md-6">
                                                                <div className="input-item input-item-name ltn__custom-icon">
                                                                    <Input
                                                                        type='number'
                                                                        size='large'
                                                                        name="maxSize"
                                                                        min={0}
                                                                        placeholder='Maximum size in square meters'
                                                                        onChange={onChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-6 col-md-6">
                                                                <div className="input-item input-item-name ltn__custom-icon">
                                                                    <Input
                                                                        type='number'
                                                                        size='large'
                                                                        name="minPrice"
                                                                        min={0}
                                                                        placeholder='Minimum Property Price in USD'
                                                                        onChange={onChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-6 col-md-6">
                                                                <div className="input-item input-item-name ltn__custom-icon">
                                                                    <Input
                                                                        type='number'
                                                                        size='large'
                                                                        name="maxPrice"
                                                                        min={0}
                                                                        placeholder='Maximum Property Price in USD'
                                                                        onChange={onChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="car-price-filter-range col-lg-12">
                                                                <div className="btn-wrapper text-center go-top">
                                                                    <button type="submit" className="btn theme-btn-1 btn-effect-1 text-uppercase">Search Properties</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane fade show"
                                                    id="ltn__form_tab_1_2"
                                                >
                                                    <div className="car-dealer-form-inner"
                                                    >

                                                        <form
                                                            // action='/properties'
                                                            className="ltn__car-dealer-form-box row"
                                                            id='buy_form'
                                                            onSubmit={handleSearchInvestmentSubmission}
                                                        >
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-4 col-md-6">
                                                                <Select
                                                                    placeholder="Select Property Type"
                                                                    size='large'
                                                                    style={{
                                                                        minWidth: 200,
                                                                        width: '100%',
                                                                        fontSize: 16,
                                                                    }}
                                                                    onChange={handleApprtmentChange}
                                                                    options={(propertyTypesData?.PropertyTypes || []).map((x) => {
                                                                        return {
                                                                            label: x.name,
                                                                            value: x._id
                                                                        }
                                                                    })}
                                                                />
                                                            </div>

                                                            <div className=" col-lg-4 col-md-6">
                                                                <Select
                                                                    placeholder="Select City"
                                                                    size='large'
                                                                    style={{
                                                                        minWidth: 200,
                                                                        width: '100%',
                                                                        fontSize: 16,
                                                                    }}
                                                                    onChange={handleCityChange}
                                                                    options={(propertyCitiesData?.PropertyCities || []).map((x) => {
                                                                        return {
                                                                            label: x.name,
                                                                            value: x._id
                                                                        }
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-4 col-md-6">
                                                                <Select
                                                                    ref={surburbRef}
                                                                    placeholder="Select a surbub"
                                                                    size='large'
                                                                    style={{
                                                                        minWidth: 200,
                                                                        width: '100%',
                                                                        fontSize: 16,
                                                                    }}
                                                                    value={suburb}
                                                                    onChange={handleSuburbChange}
                                                                    options={(state?.propertySuburbs || [])?.map((x) => {
                                                                        return {
                                                                            label: x.name,
                                                                            value: x._id
                                                                        }
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-6 col-md-6">
                                                                <div className="input-item input-item-name ltn__custom-icon">
                                                                    <Input
                                                                        type='number'
                                                                        size='large'
                                                                        name="minSharePrice"
                                                                        placeholder='Minimum Share Price'
                                                                        onChange={onInvestmentChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-6 col-md-6">
                                                                <div className="input-item input-item-name ltn__custom-icon">
                                                                    <Input
                                                                        type='number'
                                                                        size='large'
                                                                        name="maxSharePrice"
                                                                        placeholder='Maximum Share Price'
                                                                        onChange={onInvestmentChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="car-price-filter-range col-lg-12">
                                                                <div className="btn-wrapper text-center go-top">
                                                                    <button type="submit" className="btn theme-btn-1 btn-effect-1 text-uppercase">Search Investments</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="ltn__form_tab_1_3">
                                                    <div className="car-dealer-form-inner"
                                                    >
                                                        <form
                                                            // action='/properties'
                                                            className="ltn__car-dealer-form-box row"
                                                            id='buy_form'
                                                            onSubmit={handleRequestSubmission}
                                                        >
                                                            <h6 style={{fontWeight: 400}}>Current Property Ownership</h6>
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-12 col-md-12">
                                                                <Select
                                                                    placeholder="Do you have a property in Zimbabwe ?"
                                                                    allowClear
                                                                    size='large'

                                                                    style={{
                                                                        minWidth: 200,
                                                                        width: '100%',
                                                                        fontSize: 16,
                                                                    }}
                                                                    name = "zimPropertyOwnership"
                                                                    onChange={e => setWish({...wish, zimPropertyOwnership : e})}
                                                                    options={[
                                                                        {
                                                                            label: 'Yes',
                                                                            value: "yes"
                                                                        },
                                                                        {
                                                                            label: 'No',
                                                                            value: "no"
                                                                        },
                                                                    ]}
                                                                />
                                                            </div>
                                                            <h6 style={{fontWeight: 400}}>My Request (Where do you want to buy property)</h6>
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-6 col-md-6">
                                                                <Select
                                                                    placeholder="Select City"
                                                                    size='large'
                                                                    style={{
                                                                        minWidth: 200,
                                                                        width: '100%',
                                                                        fontSize: 16,
                                                                    }}
                                                                    name = "city"
                                                                    onChange={e => {setSuburbs(propertyCitiesData?.PropertyCities.filter(x => x._id == e)[0].suburbs); setWish({...wish, city : e})}}
                                                                    options={(propertyCitiesData?.PropertyCities || []).map((x) => {
                                                                        return {
                                                                            label: x.name,
                                                                            value: x._id
                                                                        }
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-6 col-md-6">
                                                                <Select
                                                                    ref={surburbRef}
                                                                    placeholder="Select a suburb"
                                                                    size='large'
                                                                    style={{
                                                                        minWidth: 200,
                                                                        width: '100%',
                                                                        fontSize: 16,
                                                                    }}
                                                                    name = "suburb"
                                                                    onChange={e => setWish({...wish, subLocation : e})}
                                                                    options={suburbs.map((x) => {
                                                                        return {
                                                                            label: x.name,
                                                                            value: x._id
                                                                        }
                                                                    })}
                                                                />
                                                            </div>
                                                            <h6 style={{fontWeight: 400}}>My Budget</h6>
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-ring col-lg-6 col-md-6">
                                                                <Select
                                                                    ref={surburbRef}
                                                                    placeholder="Select Budget Range"
                                                                    size='large'
                                                                    style={{
                                                                        minWidth: 200,
                                                                        width: '100%',
                                                                        fontSize: 16,
                                                                    }}
                                                                    name = "budget"
                                                                    onChange={e => setWish({...wish, budget : e})}
                                                                    options={[
                                                                        {
                                                                            label: '$5000 - $10000',
                                                                            value: '$5000 - $10000'
                                                                        },
                                                                        {
                                                                            label: '$10001 - $20000',
                                                                            value: '$10001 - $20000'
                                                                        },
                                                                        {
                                                                            label: '$20001 - $30000',
                                                                            value: '$20001 - $30000'
                                                                        },
                                                                        {
                                                                            label: '$30001 - $50000',
                                                                            value: '$30001 - $50000'
                                                                        },
                                                                        {
                                                                            label: '$50001 - $75000',
                                                                            value: '$50001 - $75000'
                                                                        },
                                                                        {
                                                                            label: '$75001 - $100000',
                                                                            value: '$75001 - $100000'
                                                                        },
                                                                        {
                                                                            label: '$100001 - $150000',
                                                                            value: '$100001 - $150000'
                                                                        },
                                                                        {
                                                                            label: '$150001 - $200000',
                                                                            value: '$150001 - $200000'
                                                                        },
                                                                        {
                                                                            label: '$200001 - $250000',
                                                                            value: '$200001 - $250000'
                                                                        },
                                                                        {
                                                                            label: '$250001 - $300000',
                                                                            value: '$250001 - $300000'
                                                                        },
                                                                        {
                                                                            label: '$300001 - $350000',
                                                                            value: '$300001 - $350000'
                                                                        },
                                                                        {
                                                                            label: '$350001 - $400000',
                                                                            value: '$350001 - $400000'
                                                                        },
                                                                        {
                                                                            label: '$400001+',
                                                                            value: '$400000+'
                                                                        },
                                                                    ]}
                                                                />
                                                            </div>
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-6 col-md-6">
                                                                <div className="input-item input-item-name ltn__custom-icon">
                                                                    <Input
                                                                        type='number'
                                                                        size='large'
                                                                        name="deposit"
                                                                        placeholder='Enter deposit amount'
                                                                        onChange={onWishChange}
                                                                    />

                                                                </div>
                                                            </div>
                                                            <h6 style={{fontWeight: 400}}>Timelines (let us know when you want this property)</h6>
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-6 col-md-6">
                                                                <div className="input-item input-item-name ltn__custom-icon">
                                                                    <Select
                                                                        ref={surburbRef}
                                                                        placeholder="Timelines"
                                                                        size='large'
                                                                        style={{
                                                                            minWidth: 200,
                                                                            width: '100%',
                                                                            fontSize: 16,
                                                                        }}
                                                                        name = "timeLine"
                                                                        onChange={e => setWish({...wish, timeLine : e})}
                                                                        options={[
                                                                            {
                                                                                value: "Immediately",
                                                                                label: "Immediately"
                                                                            },
                                                                            {
                                                                                value: "1-3 Months",
                                                                                label: "1-3 Months"
                                                                            },
                                                                            {
                                                                                value: "4-6 Months",
                                                                                label: "4-6 Months"
                                                                            },
                                                                            {
                                                                                value: "7-12 Months",
                                                                                label: "7-12 Months"
                                                                            },
                                                                            {
                                                                                value: ">12 Months",
                                                                                label: ">12 Months"
                                                                            }
                                                                        ]}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-6 col-md-6">
                                                                <div className="input-item input-item-name ltn__custom-icon">
                                                                    <Select
                                                                        ref={surburbRef}
                                                                        placeholder="purpose"
                                                                        size='large'
                                                                        style={{
                                                                            minWidth: 200,
                                                                            width: '100%',
                                                                            fontSize: 16,
                                                                        }}
                                                                        name = "purpose"
                                                                        onChange={e => setWish({...wish, purpose : e})}
                                                                        options={[
                                                                            {
                                                                                value: "own use",
                                                                                label: "own use"
                                                                            },
                                                                            {
                                                                                value: "for rentals",
                                                                                label: "for rentals"
                                                                            },
                                                                            {
                                                                                value: "for extended family",
                                                                                label: "for extended family"
                                                                            },
                                                                        ]}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <h6 style={{fontWeight: 400}}>Gross Income</h6>
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-ring col-lg-12 col-md-6">
                                                                <Select
                                                                    ref={surburbRef}
                                                                    placeholder="Select Gross Income"
                                                                    size='large'
                                                                    style={{
                                                                        minWidth: 200,
                                                                        width: '100%',
                                                                        fontSize: 16,
                                                                    }}
                                                                    name = "grossIncome"
                                                                    onChange={e => setWish({...wish, grossIncome : e})}
                                                                    options={[
                                                                        {
                                                                            label: '$5000 - $10000',
                                                                            value: '$5000 - $10000'
                                                                        },
                                                                        {
                                                                            label: '$10001 - $20000',
                                                                            value: '$10001 - $20000'
                                                                        },
                                                                        {
                                                                            label: '$20001 - $30000',
                                                                            value: '$20001 - $30000'
                                                                        },
                                                                        {
                                                                            label: '$30001 - $50000',
                                                                            value: '$30001 - $50000'
                                                                        },
                                                                        {
                                                                            label: '$50001 - $75000',
                                                                            value: '$50001 - $75000'
                                                                        },
                                                                        {
                                                                            label: '$75001 - $100000',
                                                                            value: '$75001 - $100000'
                                                                        },
                                                                        {
                                                                            label: '$100001+',
                                                                            value: '$100001+'
                                                                        },
                                                                    ]}
                                                                />
                                                            </div>
                                                            <h6 style={{fontWeight: 400}}>How much do you send to Zimbabwe</h6>
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-ring col-lg-6 col-md-6">
                                                                <Select
                                                                    ref={surburbRef}
                                                                    placeholder="How much do you send to Zimbabwe annually"
                                                                    size='large'
                                                                    style={{
                                                                        minWidth: 200,
                                                                        width: '100%',
                                                                        fontSize: 16,
                                                                    }}
                                                                    name = "amountOfMoneySendToZim"
                                                                    onChange={e => setWish({...wish, amountOfMoneySendToZim : e})}
                                                                    options={[
                                                                        {
                                                                            label: '$5000 - $10000',
                                                                            value: '$5000 - $10000'
                                                                        },
                                                                        {
                                                                            label: '$10001 - $20000',
                                                                            value: '$10001 - $20000'
                                                                        },
                                                                        {
                                                                            label: '$20001 - $30000',
                                                                            value: '$20001 - $30000'
                                                                        },
                                                                        {
                                                                            label: '$30001 - $50000',
                                                                            value: '$30001 - $50000'
                                                                        },
                                                                        {
                                                                            label: '$50001 - $75000',
                                                                            value: '$50001 - $75000'
                                                                        },
                                                                        {
                                                                            label: '$75001 - $100000',
                                                                            value: '$75001 - $100000'
                                                                        },
                                                                        {
                                                                            label: '$100001+',
                                                                            value: '$100001+'
                                                                        },
                                                                    ]}
                                                                />
                                                            </div>
                                                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-6 col-md-6">
                                                                <div className="input-item input-item-name ltn__custom-icon">
                                                                    <Select
                                                                        ref={surburbRef}
                                                                        placeholder="Use for money sent to Zimbabwe"
                                                                        size='large'
                                                                        style={{
                                                                            minWidth: 200,
                                                                            width: '100%',
                                                                            fontSize: 16,
                                                                        }}
                                                                        name = "moneyPurpose"
                                                                        onChange={e => setWish({...wish, moneyPurpose : e})}
                                                                        options={[
                                                                            {
                                                                                value: "Immediate family",
                                                                                label: "Immediate family"
                                                                            },
                                                                            {
                                                                                value: "for extended family",
                                                                                label: "for extended family"
                                                                            },
                                                                            {
                                                                                value: "Business",
                                                                                label: "Business"
                                                                            },
                                                                            {
                                                                                value: "Project",
                                                                                label: "Project"
                                                                            },
                                                                        ]}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="car-price-filter-range col-lg-12">
                                                                <div className="btn-wrapper text-center go-top">
                                                                    <button type="submit" className="btn theme-btn-1 btn-effect-1 text-uppercase">Submit Request</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerMain
