import ApiClient from "./Client";
import { getLoggedInUser} from './Cookie';
import {
  NotificationManager,
} from "react-notifications";

const version = '/api/v1';

const requestParams = {
  headers:{
    'x-access-token':getLoggedInUser()?.token
  }
};

class RequestService {

  create = async (data) => {
    try {
      console.log(data)
      const response = await ApiClient().post(`${version}/property/requests`, data, requestParams);
      NotificationManager.success('Successfully created a new property request');
      return response.data;
    } catch (error) {
      NotificationManager.error('Failed to create a new property request');
      return null;
    }
  }

  getPropertyRequests = async () => {
    try {
      const response = await ApiClient().get(`${version}/property/requests/user`, requestParams);
      return response.data;
    } catch (error) {
      NotificationManager.error('Failed to retrieve property requests');
      return null;
    }
  }

  deletePropertyRequests = async (id) => {
    try {  
      const response = await ApiClient().delete(`${version}/property/requests/${id}`); 
      NotificationManager.success('Successfully deleted the property request');
      return response.data;
    } catch (error) {
      NotificationManager.error('Failed to delete the property request');
      return null;
    }
  }

  updatePropertyRequests = async (data) => {
    try {  
      const { id, ...payload } = data;
      const response = await ApiClient().patch(`${version}/property/requests/update/single/${data.id}`, payload, requestParams);      
      NotificationManager.success('Successfully updated the property request');
      return response.data;
    } catch (error) { 
      NotificationManager.error('Failed to update the property request');    
      return null;
    }
  }
}

export default new RequestService();
