import React from 'react';
import {useState} from 'react';
import {getDecodedUser, getEntityCountry, getWalletId, transactionId} from '../../api/Cookie';
import Input from './Input';

const InstantPayments = () => {

    const [state,setState] = useState({ 
        m1:"ee5b1229-9fac-435c-9546-032e64c76e7d",
    });

    const handleChange =(e)=>{
        setState({...state,[e.target.name]:e.target.value});
    };

    const handleSubmit = (e) => {
        setState({...state,loading:true});
    };


  return (
    <div>
        <p style={{marginBottom:20,color:'#3b3b3b',fontSize:12}}>Instant payments are approved instantly, you'll be redirected to a payment gateway to complete this transaction</p>
        <form
        onSubmit={handleSubmit}
        name="makeP"
        id="makeP"
        method="POST"
        action="https://paynow.netcash.co.za/site/paynow.aspx"
        >
        <Input type="text" name="m1" value="49f00332-7b4c-40b1-aaa0-485f09b4e6ec" hidden edit />
        <Input type="text" name="m2" value="24ade73c-98cf-47b3-99be-cc7b867b3080" hidden edit/>
        <Input type="text" name="p2" value={transactionId()} hidden edit/>
        <Input type="text" name="p3" value={getDecodedUser().username+" deposit to wallet"} hidden edit/>
        <Input type="text" name="m4" value={getDecodedUser().id} hidden edit/> { /** send user id as extra field */ }
        <Input type="text" name="m5" value={getWalletId(getEntityCountry())} hidden edit/> {/* send suspense account as extra field */}
        <Input type="text" name="m6" value={getEntityCountry()} hidden edit/> {/* send send authentication token as another field */}
        <Input type="text" name="Budget" value="N" hidden edit/>
        <div style={{marginTop:-120}}>
            <Input
                type="text"
                placeHolder="example@gmail.com"
                min={0}
                edit
                name="m9"
                onChange={handleChange}
                label="Enter billing email"
                />
            <Input
                type="number"
                placeHolder="0.00"
                min={0}
                edit
                name="p4"
                onChange={handleChange}
                label="Enter Amount (ZAR)"
            />
                <div style={{marginTop:20,marginLeft:10,width:'100%'}}>
                <button 
                type='submit'
                disabled={state.loading}
                className='btn btn-primary'>
                    Pay Now
                </button>
            </div>
        </div>
        </form>
    </div>
  )
}

export default InstantPayments