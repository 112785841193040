import React, {Component, useRef} from "react";
import {Link} from "react-router-dom";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import Navbar from "../global-components/navbar";
import Footer_v1 from "../global-components/footer";
import ProductSlider from "../shop-components/product-slider-v1";
import LoadingBox from "../functional-components/LoadingBox";
import {useState, useEffect} from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {useSelector, useDispatch} from "react-redux";
import {userActions} from "../../redux/store";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Page_header from "../global-components/page-header";
import {useQuery, useMutation, useQueryClient} from "react-query";
import PropertyService from "../../api/PropertyService";
import LoadingBar from "react-top-loading-bar";
import {getDecodedUser, getEntityCountry, getGlobalSettings} from "../../api/Cookie";
import AccountsService from "../../api/AccountsService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownload, faDownload } from "@fortawesome/free-solid-svg-icons";

const id = window.location.href.split('?')[1] || "";

const InvestmentDetails = (data) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [userAccount, setUserAccount] = useState("");
    const [loading, setLoading] = useState(true);
    const [investment, setInvestment] = useState(0);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const ref = useRef(null);


    let publicUrl = process.env.PUBLIC_URL + "/";
    const country = getEntityCountry();
    const rateToUSD = getGlobalSettings().USDTOZARRATE
        ;
    const percentage = 0;

    function currencyFormat(num) {
        if (!num) {
            return 0;
        }
        const moneyString = country === "Zimbabwe" ? "$" : "ZAR";
        if (country !== "Zimbabwe") {
            num = num * rateToUSD;
        }

        return moneyString + " " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }

    const {data: fundingAccount, isLoading: isLoadingAccount, error: errorAccount, refetch} = useQuery("fundingAccountInvestment", () => AccountsService.getUserSuspenseAccount(country));


    const FormSubmit = (e) => {
        e.preventDefault();
        const numberOfShares = investment / singleProperty?.data?.property?.sharePrice
        // handleClose();
        if (fundingAccount?.data?.account.balance < parseFloat(investment)) {
            NotificationManager.error(
                <p style={{color: '#fff', fontSize: 12}}>Insuficient funds</p>,
                <h1 style={{color: '#fff', fontSize: 13}}>You have insuficient funds to perfom this transaction</h1>

            );
            return;
        } else {
            ref.current.continuousStart();
            const bodyData = {
                investmentAmount: investment,
                property: singleProperty?.data?.property._id,
                account: fundingAccount?.data?.account._id,
                propertySharePrice: singleProperty?.data?.property?.sharePrice,
                numberOfShares
            }
            mutate(bodyData);
        }



    };


    const {data: singleProperty, isLoading, error, refetch: resetProperty} = useQuery("property", () => PropertyService.getSinglePropertyList(id), {
        onSettled: ((x) => {
            ref.current.complete();
            if (error) setShouldRedirect(true);
        }),
        onComplete: ((x) => {
            const property = x.data.property;
            if (!property) {
                setShouldRedirect(true);
            }
        }),
        onError: ((x) => {
            setShouldRedirect(true);
        })
    });

    const {data: userInvestment} = useQuery("userinvestments", () => AccountsService.userInvestment(id), {
        onSuccess: (x) => {
            // console.log(x)
        },
        onSettled: (x) => {
            // console.log(x);
        }
    });

    const {mutate, isLoading: loadingNew} = useMutation(AccountsService.initiateInvestment, {
        onSettled: (data) => {
            ref.current.complete();
            if (data.status === 200) {
                NotificationManager.success(
                    <p style={{color: '#fff', fontSize: 12}}>Request Received Succesfully</p>,
                    <h1 style={{color: '#fff', fontSize: 13}}>{`You Have Successfully Bought ${parseFloat(investment / singleProperty?.data?.property?.sharePrice)} Shares In ${singleProperty?.data?.property?.title}`}</h1>
                );
                refetch();
                resetProperty();
                setInvestment();
                document.location.reload();
            }
        }
    })


    const caculatePercentage = () => {

        const percentage = ((singleProperty?.data?.property?.totalRaised || 0) / (singleProperty?.data?.property?.fundingTarget || 0)) * 100;

        return parseFloat(percentage || 0).toFixed(2);
    }

    return (
        <div className="ltn__shop-details-area pb-10">
            <Navbar />
            <Page_header headertitle={singleProperty?.data?.property?.title} />
            {/* <ProductSlider images={singleProperty?.data?.property?.propertyImages}/> */}
            <NotificationContainer />
            <LoadingBar progress={5} color="green" ref={ref} />
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                            <h1>{singleProperty?.data?.property?.title}</h1>
                            <label>
                                <span className="ltn__secondary-color">
                                    <i className="flaticon-pin" />
                                </span>{" "}
                                {singleProperty?.data?.property?.city?.name}, {singleProperty?.data?.property?.subLocation?.name}
                            </label>
                            <br></br>
                             <ProgressBar
                                variant="success"
                                now={caculatePercentage()}
                                label={`${caculatePercentage()}%`}
                                style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    width: "95%",
                                    marginLeft: "5px",
                                }}
                            />
                            <h4 className="title-2">Funding Target: {currencyFormat(singleProperty?.data?.property?.fundingTarget || 0)}</h4>
                            {/* <div style={{display:'flex',width:'80%',justifyContent:'space-between'}}>
                                <section>
                                    <h4 className="title-2" style={{fontWeight:500,fontSize:15}} >
                                        <span className="ltn__secondary-color">
                                            <i className="flaticon-secure"/>
                                        </span>{" "}
                                        OFFER OPENS, 3 Aug 2023
                                    </h4>
                                </section>
                                <section>
                                    <h4 className="title-2" style={{fontWeight:500,fontSize:15}}>
                                        <span className="ltn__secondary-color">
                                            <i className="flaticon-secure" />
                                        </span>{" "}
                                        OFFER CLOSES, 12 Dec 2023
                                    </h4>
                                </section>
                            </div> */}
                            <Button
                                    style={{backgroundColor: "#47878a"}}
                                    type="submit"
                                    disable={
                                        singleProperty?.data?.property?.marketValuation === singleProperty?.data?.property?.fundingTarget
                                    }
                                    onClick={handleShow}
                                >
                                    Invest Now
                            </Button>
                            <br></br>
                            <br></br>
                            <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                                <ul>
                                    <li>
                                        <label>Total Raised: </label>{" "}
                                        <span>{currencyFormat(singleProperty?.data?.property?.totalRaised || 0)}</span>
                                    </li>
                                    <li>
                                        {/* <label> Est Unit Rental Yield:</label> <span>{currencyFormat(singleProperty?.data?.property?.estimatedNetRentalYield || 0)}</span> */}
                                        <label> Est Unit Rental Yield:</label> <span>{singleProperty?.data?.property?.estimatedNetRentalYield || 0}%</span>
                                    </li>
                                    <li>
                                        <label>Tsigiro Trust Fund Discount:</label> <span>{singleProperty?.data?.property?.discount || 0}%</span>
                                    </li>
                                    <li>
                                        {/* <label>Year built:</label> <span>1992</span> */}
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <label>Market Valuation</label>  <span>{currencyFormat(singleProperty?.data?.property?.marketValuation || 0)}</span>
                                    </li>
                                    <li>
                                        <label>Share Price:</label>{" "}
                                        <span>{currencyFormat(singleProperty?.data?.property?.sharePrice || 0)}</span>
                                    </li>
                                    <li>
                                        <label>Internal Rate Of Return:</label>{" "}
                                        <span>{singleProperty?.data?.property?.internalRateOfReturn || 0}%</span>
                                    </li>
                                </ul>
                               
                            </div>
                             
                            <h4 className="title-2">Description</h4>
                            <div dangerouslySetInnerHTML={{__html : singleProperty?.data?.property?.description}}></div>

                            <h4 className="title-2">Documents</h4>
                                {
                                    (singleProperty?.data?.property.documents || [] ).map(path=><>
                                            <Card style={{border : '.9 solid #8391a2', paddingBottom:0, paddingLeft:"0.5%", marginBottom:"1%", paddingBottom:"0.3%"}}>
                                                <div>
                                                    <a  style={{color : "#8391a2", lineHeight: "250%"}} download={`${process.env.REACT_APP_SPACES_URL}${path}`} href={`${process.env.REACT_APP_SPACES_URL}${path}`}>
                                                        {path?.split("-")[1].toUpperCase()}
                                                    </a>
                                                    <a 
                                                    href={`${process.env.REACT_APP_SPACES_URL}${path}`}
                                                    style={{ border : "1px solid rgb(71, 134, 137)", height:34, paddingTop:5, borderRadius:4, margin : ".5%",backgroundColor :"white", color : "#478689",float:"right", padding:'.5% 2% 3.8% 1%',  marginRight :"1%"}}>
                                                        <FontAwesomeIcon icon={faDownload} /> Download
                                                    </a>
                                                </div>
                                            </Card>
                                    </>)
                                }
                                

                             <h4 className="title-2">Property Images</h4>
                            <div className="ltn__property-details-gallery mb-30">
                                <div className="row">
                                    {
                                        (singleProperty?.data?.property?.propertyImages || []).map((x, index) => {
                                            return <div className="col-md-6" key={index}> <a key={index}
                                                href={process.env.REACT_APP_SPACES_URL + x}
                                                data-rel="lightcase:myCollection"
                                            >
                                                <img
                                                    style={{maxHeight: "50vh",
                                                    minHeight: "50vh",   
                                                    minWidth : "100%",
                                                    maxWidth : "100%",                                 
                                                    overflow: "hidden"}}
                                                    className="mb-30"
                                                    src={process.env.REACT_APP_SPACES_URL + x}
                                                    alt="Image"
                                                />
                                            </a>
                                            </div>

                                        })
                                    }
                                </div>
                            </div>
                             {/* <h4 className="title-2">Floor Plans</h4>
                        <div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
                            <div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center---">
                                <div className="nav">
                                    <a data-bs-toggle="tab" href="#liton_tab_3_1">
                                        First Floor
                                    </a>
                                    <a
                                        className="active show"
                                        data-bs-toggle="tab"
                                        href="#liton_tab_3_2"
                                    >
                                        Second Floor
                                    </a>
                                    <a data-bs-toggle="tab" href="#liton_tab_3_3">
                                        Third Floor
                                    </a>
                                    <a data-bs-toggle="tab" href="#liton_tab_3_4">
                                        Top Garden
                                    </a>
                                </div>
                            </div>
                            <div className="tab-content">
                                <div className="tab-pane fade" id="liton_tab_3_1">
                                    <div className="ltn__apartments-tab-content-inner">
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="apartments-plan-img">
                                                    <img
                                                        src={publicUrl + "assets/img/others/10.png"}
                                                        alt="#"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                                                    <h2>First Floor</h2>
                                                    <p>
                                                        Enimad minim veniam quis nostrud exercitation
                                                        ullamco laboris. Lorem ipsum dolor sit amet cons
                                                        aetetur adipisicing elit sedo eiusmod
                                                        tempor.Incididunt labore et dolore magna aliqua. sed
                                                        ayd minim veniam.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade active show" id="liton_tab_3_2">
                                    <div className="ltn__product-tab-content-inner">
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="apartments-plan-img">
                                                    <img
                                                        src={publicUrl + "assets/img/others/10.png"}
                                                        alt="#"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                                                    <h2>Second Floor</h2>
                                                    <p>
                                                        Enimad minim veniam quis nostrud exercitation
                                                        ullamco laboris. Lorem ipsum dolor sit amet cons
                                                        aetetur adipisicing elit sedo eiusmod
                                                        tempor.Incididunt labore et dolore magna aliqua. sed
                                                        ayd minim veniam.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="product-details-apartments-info-list  section-bg-1">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="apartments-info-list apartments-info-list-color mt-40---">
                                                                <ul>
                                                                    <li>
                                                                        <label>Total Area</label>{" "}
                                                                        <span>2800 Sq. Ft</span>
                                                                    </li>
                                                                    <li>
                                                                        <label>Bedroom</label>{" "}
                                                                        <span>150 Sq. Ft</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="apartments-info-list apartments-info-list-color mt-40---">
                                                                <ul>
                                                                    <li>
                                                                        <label>Belcony/Pets</label>{" "}
                                                                        <span>Allowed</span>
                                                                    </li>
                                                                    <li>
                                                                        <label>Lounge</label>{" "}
                                                                        <span>650 Sq. Ft</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="liton_tab_3_3">
                                    <div className="ltn__product-tab-content-inner">
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="apartments-plan-img">
                                                    <img
                                                        src={publicUrl + "assets/img/others/10.png"}
                                                        alt="#"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                                                    <h2>Third Floor</h2>
                                                    <p>
                                                        Enimad minim veniam quis nostrud exercitation
                                                        ullamco laboris. Lorem ipsum dolor sit amet cons
                                                        aetetur adipisicing elit sedo eiusmod
                                                        tempor.Incididunt labore et dolore magna aliqua. sed
                                                        ayd minim veniam.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="product-details-apartments-info-list  section-bg-1">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="apartments-info-list apartments-info-list-color mt-40---">
                                                                <ul>
                                                                    <li>
                                                                        <label>Total Area</label>{" "}
                                                                        <span>2800 Sq. Ft</span>
                                                                    </li>
                                                                    <li>
                                                                        <label>Bedroom</label>{" "}
                                                                        <span>150 Sq. Ft</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="apartments-info-list apartments-info-list-color mt-40---">
                                                                <ul>
                                                                    <li>
                                                                        <label>Belcony/Pets</label>{" "}
                                                                        <span>Allowed</span>
                                                                    </li>
                                                                    <li>
                                                                        <label>Lounge</label>{" "}
                                                                        <span>650 Sq. Ft</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="liton_tab_3_4">
                                    <div className="ltn__product-tab-content-inner">
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="apartments-plan-img">
                                                    <img
                                                        src={publicUrl + "assets/img/others/10.png"}
                                                        alt="#"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                                                    <h2>Top Garden</h2>
                                                    <p>
                                                        Enimad minim veniam quis nostrud exercitation
                                                        ullamco laboris. Lorem ipsum dolor sit amet cons
                                                        aetetur adipisicing elit sedo eiusmod
                                                        tempor.Incididunt labore et dolore magna aliqua. sed
                                                        ayd minim veniam.
                                                    </p>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
                            {/* Form Widget */}
                            <div className="widget ltn__form-widget">
                                <h4 className="ltn__widget-title ltn__widget-title-border-2">
                                    Your Investments Summary
                                </h4>
                                <ul>
                                    <li>
                                        <label>Shares: </label>{" "}
                                        <span>{(userInvestment?.data?.investment?.numberOfShares || 0)?.toFixed(2)}</span>
                                    </li>
                                    <li>
                                        <label>Net Value: </label>{" "}
                                        <span>{currencyFormat((singleProperty?.data?.property?.sharePrice) * (userInvestment?.data?.investment?.numberOfShares))}</span>
                                    </li>
                                </ul>

                                

                            </div>
                            <div className="widget ltn__form-widget">
                                <h4 className="ltn__widget-title ltn__widget-title-border-2">
                                    Talk To Sales About This Investment
                                </h4>
                                <p>You can directly contact our sales agents for more details on this project</p>

                                <div className="product-info-bottom">
                                    <div className=" ltn__product-item-4 ltn__product-item-5" style={{border: "none", boxShadow: 'none'}}>
                                        <div className="product-hover-action">
                                            <ul>
                                                <li>
                                                <a className="col-md-2" href=
                                                    {`https://wa.me/${singleProperty.data.property?.agent?.phone}?text=hello%20my%20tsigiro%20id%20is%2C%20${getDecodedUser().userAccountNumber}%0AI
                                                        'm%20interested%20in%20purchasing%20this%20property%2C%20http%3A%2F%2Fproperty.tsigiro.com%2Fproperty-details%3${singleProperty.data.property?._id}%0Acould%20you%20please%20assist`
                                                    }
                                                >
                                                        <i className="icon-whatsapp" style={{color: 'green', fontSize: 20, cursor: 'pointer'}}></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="col-md-2"
                                                        href=
                                                        {
                                                            `mailto:${singleProperty.data.property?.agent?.email}?subject=Interested%20In%20Property&body=https%3A%2F%2Fproperty.tsigiro.com%2Fproperty-details?${singleProperty.data.property?._id}
                                                             `
                                                        }
                                                    >
                                                        <i className="icon-mail" style={{color: 'green', fontSize: 20, cursor: 'pointer'}}></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a  title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal" href={`tel:${singleProperty.data.property?.agent?.phone}`}>
                                                        <i className="icon-phone-call col-md-2" style={{color: 'green', fontSize: 20, cursor: 'pointer'}}></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </aside>
                    </div>
                </div>
            </div>
            <Footer_v1 />
            <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton style={{padding: 30}}>
                    <Modal.Title
                        style={{textAlign: "center", fontWeight: "bold"}}
                    >
                        Invest In {singleProperty?.data?.property?.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label>Your Account Balance</Form.Label>
                            <p style={{margin: 0, fontWeight: 600}}>
                                {
                                    currencyFormat(fundingAccount?.data?.account?.balance || 0)
                                }
                            </p>
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput4"
                        >
                            <Form.Label>Total Funding Received</Form.Label>
                            <p style={{margin: 0, fontWeight: 600}}>
                                {
                                    currencyFormat(singleProperty?.data?.property?.totalRaised || 0)
                                }
                            </p>
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput6"
                        >
                            <Form.Label>Price Per Share</Form.Label>
                            <p style={{margin: 0, fontWeight: 600}}>
                                {
                                    currencyFormat(singleProperty?.data?.property?.sharePrice || 0)
                                }
                            </p>
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput2"
                        >
                            <Form.Label>
                                Remaining Investment Needed ({country === "Zimbabwe" ? "USD" : "ZAR"})
                            </Form.Label>
                            <p style={{margin: 0, fontWeight: 600}}>
                                {
                                    currencyFormat((singleProperty?.data?.property?.fundingTarget || 0) - (singleProperty?.data?.property?.totalRaised || 0))
                                }
                            </p>
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput3"
                        >
                            <Form.Label>
                                Amount You Want To Invest ({country === "Zimbabwe" ? "USD" : "ZAR"})*
                            </Form.Label>
                            <Form.Control
                                type="number"
                                // placeholder="100"
                                autoFocus
                                value={investment}
                                onChange={(e) => {
                                    setInvestment(e.target.value);
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose} variant="danger">
                        Close
                    </Button>
                    <Button
                        onClick={FormSubmit}
                        style={{backgroundColor: "#47878a"}}
                    >
                        Invest
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default InvestmentDetails;
