import React, {Component, useState} from "react";
import {Link} from "react-router-dom";
import AccountsService from "../../api/AccountsService";
import {getEntityCountry, getGlobalSettings, setEntityCountry, setLoggedInUser, storeWalletID} from "../../api/Cookie";
import {decoded, encoded} from "../../api/RouteHelpers";
import {SelectorConstants} from "./SelectorConstants";
import {useQuery} from "react-query";
import LoadingBox from "../functional-components/LoadingBox";
import TrustFundModal from "./TrustFundModal";
import FundWalletModal from "./FundWalletModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";


let publicUrl = process.env.PUBLIC_URL + "/";
const country = getEntityCountry();


const Navbar = () => {

    const [selected, setSelected] = React.useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showWalletModal, setWalletModal] = useState(false);
    const {data: fundingAccount, isLoading} = useQuery("fundingAccount", () => AccountsService.getUserSuspenseAccount(country), {
         onSuccess: (x) => {
            const walletID = x?.data?.account?._id;
            const country = x?.data?.account?.accountCountry;
            storeWalletID(country, walletID);
        }
    })

    /* Open when someone clicks on the span element */
    function openNav() {
        document.getElementById("myNav").style.width = "100%";
    };

    /* Close when someone clicks on the "x" symbol inside the overlay */
    function closeNav() {
        document.getElementById("myNav").style.width = "0%";
    };

    function currencyFormat(num) {
        const moneyString = country === "Zimbabwe" ? "$" : "ZAR"
        return moneyString + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }


    return (

        <div >
            <TrustFundModal
                show={showModal}
                onClose={() => setShowModal(!showModal)}
            />
            <FundWalletModal
                show={showWalletModal}
                onClose={() => setWalletModal(!showWalletModal)}
            />
            <header className="ltn__header-area ltn__header-5   gradient-color-4---" style={{borderBottom: '1px solid #eee', backgroundColor: '#fcfcfc', width: '100%'}}>
                <div className="ltn__header-top-area " style={{backgroundColor: '#334155'}}>
                    <div className="container-top-bar-menu">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="ltn__top-bar-menu">
                                    <ul>
                                        <li>
                                            <a style={{color: '#fff', marginLeft: 20, fontWeight: 400}}>
                                                Tsigiro Usekelo Profile Management
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="top-bar-right text-end">
                                    <div className="ltn__top-bar-menu">
                                        <ul>
                                            {/* <li>
                                                <a style={{color: '#ea9342', marginLeft: 20, fontWeight: 400}}>
                                                    Tsigiro Usekelo Trust Fund Inactive
                                                </a>
                                            </li> */}
                                            <li onClick={() => {
                                                setShowModal(true);
                                            }}>
                                                <a
                                                    style={{
                                                        padding: 10,
                                                        fontWeight: 400,
                                                        margin: 10,
                                                        backgroundColor: '#47878a', width: "auto",
                                                        borderRadius: 20,
                                                        color: '#fff',
                                                        marginLeft: 10, height: '30px',
                                                        display: 'flex', alignItems: 'center', justifyContent: "center"
                                                    }}
                                                >Activate Trust Fund Membership
                                                </a>
                                            </li>
                                            {/* <li onClick={() => {
                                                setLoggedInUser();
                                                document.location.reload();
                                            }}>
                                                <a href="#" >
                                                    <span style={{color: "#fff", fontWeight: 400, textAlign: 'center'}}>
                                                        logout
                                                    </span>

                                                </a>
                                            </li> */}
                                            <li 
                                                onClick={()=>{
                                                    setLoggedInUser(null);
                                                    window.location.href = process.env.REACT_APP_AUTH + "?" + encoded("logout") || '';
                                                    return null;
                                                }}
                                            className='top-nav-item logut'>
                                                    <div type="button" style={{color:"#fff",fontWeight:400}}>
                                                    <span>
                                                        <FontAwesomeIcon icon={faRightFromBracket} style={{marginRight:10}} />
                                                        <span>logout</span>
                                                    </span>
                                                    </div>
                                                </li>
                                            <li>
                                                {/* <Social /> */}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="ltn__header-middle-area ltn__header-sticky  ltn__sticky-bg-white">
                    <div className="container-navbar">
                        <div className="row">
                            <div className="col">
                                <div className="site-logo-wrap">
                                    <div className="site-logo go-top">
                                        <Link to="/">
                                            <img
                                                src={publicUrl + "assets/img/logo.png"}
                                                style={{width: "250px"}}
                                                alt="Logo"
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col header-menu-column" style={{width: '100%'}}>
                                <div className="header-menu d-none d-xl-block">
                                    <nav>
                                        <div className="ltn__main-menu go-top ">
                                            <ul >
                                                <li>
                                                    <Link to="/properties" style={{fontWeight: 400}}>Property Sales</Link>
                                                </li>
                                                <li>
                                                    <Link to="/investing" style={{fontWeight: 400}}>Property Investments</Link>
                                                </li>
                                                <li>
                                                    <Link to="/property-requests" style={{fontWeight: 400}}>My Wish List</Link>
                                                </li>
                                                <li>
                                                    <Link to="/property-purchases" style={{fontWeight: 400}}>My Purchases</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            <div className="col ltn__header-options ltn__header-options-2 mb-sm-20">
                                <div className="ltn__drop-menu user-menu">
                                    <ul>
                                        <li style={{minWidth: '130px', width: 'auto'}}>
                                            {
                                                isLoading ? <LoadingBox /> : <Link to="#" style={{width: 'auto', fontSize: 13, fontWeight: 500, paddingRight: 10, paddingLeft: 10}}>
                                                    {currencyFormat(fundingAccount?.data?.account?.balance || 0)}
                                                </Link>
                                            }
                                        </li>
                                    </ul>
                                </div>
                                <div className="ltn__drop-menu user-menu">
                                    <ul>
                                        <li>
                                            <Link to="#" style={{width: '90px', fontSize: 12, display: 'flex', justifyContent: 'space-around', alignItems: 'center', fontWeight: 500}}>
                                                Apps <i className="dripicons-view-apps" style={{fontSize: 20, marginTop: -3}} ></i>
                                            </Link>
                                            <ul
                                                className="go-top tsigiro-app-container"
                                                style={{display: 'grid', flexDirection: 'row', height: 'auto', width: 'auto', minWidth: 300, gridTemplateColumns: '1fr 1fr', gridGap: '1rem', padding: 5}}
                                            >
                                                {
                                                    SelectorConstants.map((x, index) => {
                                                        return <li
                                                            key={index}
                                                            onClick={() => x.redirectURL()}
                                                            style={{color: '#000', cursor: 'pointer', textAlign: 'center', padding: 20}}>
                                                            <i className={x.icon} style={{color: '#47878a', fontSize: 18}}></i>
                                                            {/* <img src={x.image} style={{width: 30, height: 30}} /> */}
                                                            <p className='hvr-link' style={{margin: 0, fontSize: 13, fontFamily: 'Montserrat', fontWeight: 500}}>{x.title}
                                                            </p>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="ltn__drop-menu user-menu" onClick={() => setWalletModal(true)}>
                                    <ul>
                                        <li>
                                            <Link to="#" style={{width: '100px', fontSize: 12, fontWeight: 500}}>
                                                Fund Wallet
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mobile-menu-toggle d-xl-none" onClick={openNav}>
                                    <a
                                        href="#ltn__utilize-mobile-menu"
                                        className="ltn__utilize-toggle"
                                    >
                                        <svg viewBox="0 0 800 600">
                                            <path
                                                d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                                                id="top"
                                            />
                                            <path d="M300,320 L540,320" id="middle" />
                                            <path
                                                d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                                                id="bottom"
                                                transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="myNav" className="overlay">
                    <a href="javascript:void(0)" class="closebtn" onClick={closeNav}>&times;</a>
                    <div className="overlay-content">
                        {/* {
                            Routes.map((menu, index) => {
                                if (menu.isPrivate)
                                    return (
                                        <div className='single-selection' onClick={() => {
                                            setSelected(index);
                                            closeNav();
                                        }}>
                                            <a href={menu.path} style={{color: window.location.pathname === menu.path ? "#fff" : "#000", fontSize: window.location.pathname === menu.path ? 23 : 18, fontWeight: 400}}>{menu.name}</a>
                                            {window.location.pathname === menu.path && <hr style={{height: '1px', marginBottom: -3, backgroundColor: '#fff', color: '#fff'}}></hr>}
                                        </div>
                                    )
                            })
                        } */}
                    </div>
                </div>
            </header>
            <div
                id="ltn__utilize-mobile-menu"
                className="ltn__utilize ltn__utilize-mobile-menu"
            >
                <div className="ltn__utilize-menu-inner ltn__scrollbar">
                    <div className="ltn__utilize-menu-head">
                        <div className="site-logo">
                            <Link to="/">
                                <img src={publicUrl + "assets/img/logo.png"} alt="Logo" />
                            </Link>
                        </div>
                        <button className="ltn__utilize-close">×</button>
                    </div>
                    <div className="ltn__utilize-menu">
                        <ul>
                            <li>
                                <Link to="/properties">Property Sales</Link>
                            </li>
                            <li>
                                <Link to="/investing">Property Investments</Link>
                            </li>
                            <li>
                                <Link to="/property-requests">My Wish List</Link>
                            </li>
                            <li>
                                <Link to="/property-purchases">My Purchases</Link>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
