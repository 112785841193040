import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ShopGridV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div>
			<div className="ltn__product-area ltn__product-gutter mb-100">
				<div className="container">
						<div className="row">
							<div className="col-lg-12">
							<div className="tab-content ">
								<div className="tab-pane active fade" id="liton_product_list">
								<div className="ltn__product-tab-content-inner ltn__product-list-view">
									<h2 className="product-title go-top">No Active Purchases</h2>
									<div className="product-img-location">
										<p>You can see all your active property purchases here</p>
									</div>
									<h2 className="product-title go-top">Your Property Purchases</h2>
									<div className="product-img-location">
										<p>You can see all your active property purchases here</p>
									</div>
									<div className="row">
									<div className="col-lg-12">
										<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
										<div className="product-img">
											<Link to="/product-details"><img src={publicUrl+"assets/img/product-3/1.jpg"} alt="#" /></Link>
										</div>
										<div className="product-info">
											<div className="product-badge-price">
											<div className="product-badge">
												<ul>
												<li className="sale-badg">For Rent</li>
												</ul>
											</div>
											<div className="product-price">
												<span>$34,900<label>/Month</label></span>
											</div>
											</div>
											<h2 className="product-title go-top"><Link to="/product-details">New Apartment Nice View</Link></h2>
											<div className="product-img-location">
											<ul>
												<li className="go-top">
												<Link to="/contact"><i className="flaticon-pin" /> Belmont Gardens, Chicago</Link>
												</li>
											</ul>
											</div>
											<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
											<li><span>3 </span>
												Bedrooms
											</li>
											<li><span>2 </span>
												Bathrooms
											</li>
											<li><span>3450 </span>
												Square Meters
											</li>
											</ul>
										</div>
										<div className="product-info-bottom">
											<div className="real-estate-agent">
											<div className="agent-img">
												<Link to="/team-details"><img src={publicUrl+"assets/img/blog/author.jpg"} alt="#" /></Link>
											</div>
											<div className="agent-brief go-top">
												<h6><Link to="/team-details">William Seklo</Link></h6>
												<small>Estate Agents</small>
											</div>
											</div>
											<div className="product-hover-action">
											<ul>
												<li>
												<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
													<i className="flaticon-expand" />
												</a>
												</li>
												<li>
												<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
													<i className="flaticon-heart-1" /></a>
												</li>
												<li>
												<span className="go-top">
												<Link to="/product-details" title="Product Details">
													<i className="flaticon-add" />
												</Link>
												</span>
												</li>
											</ul>
											</div>
										</div>
										</div>
									</div>																
									</div>
								</div>
								</div>
							</div>
							<div className="ltn__pagination-area text-center">
								<div className="ltn__pagination">
								<ul>
									<li><a href="#"><i className="fas fa-angle-double-left" /></a></li>
									<li><a href="#">1</a></li>
									<li className="active"><a href="#">2</a></li>
									<li><a href="#">3</a></li>
									<li><a href="#">...</a></li>
									<li><a href="#">10</a></li>
									<li><a href="#"><i className="fas fa-angle-double-right" /></a></li>
								</ul>
								</div>
							</div>
							</div>
						</div>
				</div>
			</div>
			</div>

        }
}

export default ShopGridV1