import React, {useEffect, useRef, useState} from "react";
import {Link, Redirect} from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import {Button} from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import {useQuery, useMutation} from "react-query";
import PropertyService from "../../api/PropertyService";
import LoadingBar from "react-top-loading-bar";
import 'react-notifications/lib/notifications.css';
import {getDecodedUser} from "../../api/Cookie";

const id = window.location.href.split('?')[1] || "";

const ShopDetails = (data) => {

    let publicUrl = process.env.PUBLIC_URL + "/";
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [payment, setPaymentDate] = useState("");
    const [propertyID, setPropertyID] = useState("");
    const [shouldRedirect, setShouldRedirect] = useState(id ? false : true);

    const ref = useRef(null);

    function currencyFormat(num) {
        return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }


    const {data: singleProperty, isLoading, error, refetch} = useQuery("property", () => PropertyService.getSinglePropertyList(id), {
        onSettled: ((x) => {
            ref.current.complete();
            if (error) setShouldRedirect(true);
        }),
        onComplete: ((x) => {
            const property = x.data.property;
            if (!property) {
                setShouldRedirect(true);
            }
        }),
        onError: ((x) => {
            setShouldRedirect(true);
        })
    });


    const {mutate, isLoading: isRequestToPurchaseLoading} = useMutation(PropertyService.requestToPurchase, {

        onSettled: (() => {
            ref.current.complete();
        }),

        onSuccess: (() => {
            refetch();
            NotificationManager.success(
                <p style={{color: '#fff', fontSize: 12}}>Request Received Succesfully</p>,
                <h1 style={{color: '#fff', fontSize: 13}}>Request To Purchase property</h1>
            );
        })

    });

    const FormSubmit = () => {
        mutate(singleProperty.data.property?._id);
        ref.current.continuousStart();
    };

    if (isLoading) {
        return <div class="cv-spinner">
            <LoadingBar progress={5} color="green" ref={ref} />
            <span class="spinner"></span>
        </div>
    }

    return (
        <div className="ltn__shop-details-area pb-10">
            <LoadingBar progress={5} color="green" ref={ref} />
            {shouldRedirect && <Redirect
                to="/properties"
            />}
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                            <h1>{singleProperty.data?.property?.title}</h1>
                            <label>
                                <span className="ltn__secondary-color">
                                    <i className="flaticon-pin" />
                                </span>{" "}
                                {singleProperty.data.property?.city?.name}, {singleProperty.data.property?.subLocation?.name}
                            </label>
                            <h4 className="title-2">Description</h4>
                            <div>
                                <div  dangerouslySetInnerHTML={{__html : singleProperty.data.property?.description}}></div>
                            </div>
                            <h4 className="title-2">
                                Benefits Of Being A Tsigiro Trust Fund Member
                            </h4>
                            <ul>
                                <li>Get discounts on your property purchases</li>
                                <li>Get reduced deposit fees</li>
                                <li>Get updates on hottest properties</li>
                            </ul>
                            <Button
                                type="submit"
                                variant="secondary"
                                style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                    backgroundColor: "#47878a",
                                    height: "30%",
                                }}
                            >
                                Become A Member
                            </Button>

                            <h4 className="title-2">Property Detail</h4>
                            <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                                <ul>
                                    <li>
                                        <label>Property ID:</label>{" "}
                                        <span>{singleProperty.data.property?._id}</span>
                                    </li>
                                    {
                                        singleProperty.data.property?.propertySize && <li>
                                        <label>Property Size: </label>{" "}
                                        <span>{singleProperty.data.property?.propertySize} square meters</span>
                                    </li>}
                                    {
                                        singleProperty.data.property?.bedrooms && <li>
                                        <label>Bedrooms:</label> <span>{singleProperty.data.property?.bedrooms}</span>
                                    </li>}
                                    {
                                        singleProperty.data.property?.bathrooms && <li>
                                        <label>Baths:</label> <span>{singleProperty.data.property?.bathrooms}</span>
                                    </li>}
                                    {
                                        singleProperty.data.property?.deposit  && <li>
                                        <label>Minimum Deposit:</label>{" "}
                                        <span>USD {singleProperty.data.property?.deposit || "0.00"}</span>
                                    </li>}
                                </ul>
                                <ul>
                                    {/* <li>
                                        <label>Lot Area:</label> <span>HZ29 </span>
                                    </li> */}
                                    {/* <li>
                                        <label>Lot dimensions:</label>{" "}
                                        <span>
                                            {singleProperty.data.property.x} x {singleProperty.data.property.y} m
                                        </span>
                                    </li> */}
                                    {
                                        singleProperty.data.property?.bedrooms && <li>
                                        <label>Beds:</label> <span>{singleProperty.data.property?.bedrooms}</span>
                                    </li>}
                                    {
                                        singleProperty.data.property?.price && <li>
                                        <label>Price:</label>{" "}
                                        <span>USD {currencyFormat(singleProperty.data.property?.price || 0)}</span>
                                    </li>}
                                    {
                                        singleProperty.data.property?.propertyStatus &&
                                        <li>
                                        <label>Property Status:</label>{" "}
                                        <span>{singleProperty.data.property?.propertyStatus}</span>
                                    </li>}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4" style={{marginTop: "100px"}}>
                        <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
                            {/* Form Widget */}
                            <div className="widget ltn__form-widget">
                                <h4 className="ltn__widget-title ltn__widget-title-border-2">
                                    {singleProperty.data?.purchaseRequest ? " Purchase Request Submitted Successfully" : "Request To Purchase Property"}
                                </h4>
                                <p>{
                                    singleProperty.data?.purchaseRequest ? "Your request to purchase this property has been received and is being processed" : "Send a request to purchase this property and our sales agent will contact you instantly"}
                                </p>
                                <Button
                                    onClick={FormSubmit}
                                    type="submit"
                                    disabled={isRequestToPurchaseLoading || singleProperty.data?.purchaseRequest}
                                    variant="secondary"
                                    style={{marginTop: "20px", backgroundColor: "#47878a"}}
                                >
                                    {singleProperty.data?.purchaseRequest ? "Request Sent" : "Send Request"}
                                </Button>
                                <NotificationContainer />
                            </div>
                            <div className="widget ltn__form-widget">
                                <h4 className="ltn__widget-title ltn__widget-title-border-2">
                                    Talk To Sales About This Property
                                </h4>
                                <p>You can directly contact our sales agents for more details on purchasing this property</p>
                                <div style={{marginBottom: 7, display: 'flex', justifyContent: 'space-between'}}>
                                    <a className="col-md-2" href=
                                        {`https://wa.me/${singleProperty.data.property?.agent?.phone}?text=hello%20my%20tsigiro%20id%20is%2C%20${getDecodedUser().userAccountNumber}%0AI
                                             'm%20interested%20in%20purchasing%20this%20property%2C%20http%3A%2F%2Fproperty.tsigiro.com%2Fproperty-details%3${singleProperty.data.property?._id}%0Acould%20you%20please%20assist`
                                        }
                                    >
                                        <i className="icon-whatsapp" style={{color: 'green', fontSize: 20, cursor: 'pointer'}}></i>
                                    </a>
                                    <a
                                        className="col-md-2"
                                        href=
                                        {
                                            `mailto:${singleProperty.data.property?.agent?.email}?subject=Interested%20In%20Property&body=https%3A%2F%2Fproperty.tsigiro.com%2Fproperty-details?${singleProperty.data.property?._id}
                                             `
                                        }
                                    >
                                        <i className="icon-mail" style={{color: 'green', fontSize: 20, cursor: 'pointer'}}></i>
                                    </a>
                                    <a href={`tel:${singleProperty.data.property?.agent?.phone}`}>
                                        <i className="icon-phone-call col-md-2" style={{color: 'green', fontSize: 20, cursor: 'pointer'}}></i>
                                    </a>
                                </div>

                            </div>

                            {/* Banner Widget */}
                            <div className="widget ltn__banner-widget d-none go-top">
                                <Link to="/shop">
                                    <img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" />
                                </Link>
                            </div>
                        </aside>
                    </div>
                    <div className="col-12">
                        <h4 className="title-2">Property Images</h4>
                        <div className="ltn__property-details-gallery mb-30">
                            <div className="row">
                                {
                                    (singleProperty.data.property?.propertyImages || []).map((x, index) => {
                                        return <div className="col-md-6" key={index}> <a key={index}
                                            href={process.env.REACT_APP_SPACES_URL + x}
                                            data-rel="lightcase:myCollection"
                                        >
                                            <img
                                            style={{
                                                maxHeight: "70vh",
                                                minHeight: "70vh",   
                                                minWidth : "100%",
                                                maxWidth : "100%",                                 
                                                overflow: "hidden"}}
                                                className="mb-30"
                                                src={process.env.REACT_APP_SPACES_URL + x}
                                                alt="Image"
                                            />
                                        </a>
                                        </div>

                                    })
                                }
                            </div>
                        </div>
                        {/* <h4 className="title-2">Floor Plans</h4> */}
                        {/* APARTMENTS PLAN AREA START */}
                        {/* <div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
                            <div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center---">
                                <div className="nav">
                                    <a data-bs-toggle="tab" href="#liton_tab_3_1">
                                        First Floor
                                    </a>
                                    <a
                                        className="active show"
                                        data-bs-toggle="tab"
                                        href="#liton_tab_3_2"
                                    >
                                        Second Floor
                                    </a>
                                    <a data-bs-toggle="tab" href="#liton_tab_3_3">
                                        Third Floor
                                    </a>
                                    <a data-bs-toggle="tab" href="#liton_tab_3_4">
                                        Top Garden
                                    </a>
                                </div>
                            </div>
                            <div className="tab-content">
                                <div className="tab-pane fade" id="liton_tab_3_1">
                                    <div className="ltn__apartments-tab-content-inner">
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="apartments-plan-img">
                                                    <img
                                                        src={publicUrl + "assets/img/others/10.png"}
                                                        alt="#"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                                                    <h2>First Floor</h2>
                                                    <p>
                                                        Enimad minim veniam quis nostrud exercitation
                                                        ullamco laboris. Lorem ipsum dolor sit amet cons
                                                        aetetur adipisicing elit sedo eiusmod
                                                        tempor.Incididunt labore et dolore magna aliqua. sed
                                                        ayd minim veniam.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="product-details-apartments-info-list  section-bg-1">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="apartments-info-list apartments-info-list-color mt-40---">
                                                                <ul>
                                                                    <li>
                                                                        <label>Total Area</label>{" "}
                                                                        <span>2800 Sq. Ft</span>
                                                                    </li>
                                                                    <li>
                                                                        <label>Bedroom</label>{" "}
                                                                        <span>150 Sq. Ft</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="apartments-info-list apartments-info-list-color mt-40---">
                                                                <ul>
                                                                    <li>
                                                                        <label>Belcony/Pets</label>{" "}
                                                                        <span>Allowed</span>
                                                                    </li>
                                                                    <li>
                                                                        <label>Lounge</label>{" "}
                                                                        <span>650 Sq. Ft</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade active show" id="liton_tab_3_2">
                                    <div className="ltn__product-tab-content-inner">
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="apartments-plan-img">
                                                    <img
                                                        src={publicUrl + "assets/img/others/10.png"}
                                                        alt="#"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                                                    <h2>Second Floor</h2>
                                                    <p>
                                                        Enimad minim veniam quis nostrud exercitation
                                                        ullamco laboris. Lorem ipsum dolor sit amet cons
                                                        aetetur adipisicing elit sedo eiusmod
                                                        tempor.Incididunt labore et dolore magna aliqua. sed
                                                        ayd minim veniam.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="product-details-apartments-info-list  section-bg-1">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="apartments-info-list apartments-info-list-color mt-40---">
                                                                <ul>
                                                                    <li>
                                                                        <label>Total Area</label>{" "}
                                                                        <span>2800 Sq. Ft</span>
                                                                    </li>
                                                                    <li>
                                                                        <label>Bedroom</label>{" "}
                                                                        <span>150 Sq. Ft</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="apartments-info-list apartments-info-list-color mt-40---">
                                                                <ul>
                                                                    <li>
                                                                        <label>Belcony/Pets</label>{" "}
                                                                        <span>Allowed</span>
                                                                    </li>
                                                                    <li>
                                                                        <label>Lounge</label>{" "}
                                                                        <span>650 Sq. Ft</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="liton_tab_3_3">
                                    <div className="ltn__product-tab-content-inner">
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="apartments-plan-img">
                                                    <img
                                                        src={publicUrl + "assets/img/others/10.png"}
                                                        alt="#"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                                                    <h2>Third Floor</h2>
                                                    <p>
                                                        Enimad minim veniam quis nostrud exercitation
                                                        ullamco laboris. Lorem ipsum dolor sit amet cons
                                                        aetetur adipisicing elit sedo eiusmod
                                                        tempor.Incididunt labore et dolore magna aliqua. sed
                                                        ayd minim veniam.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="product-details-apartments-info-list  section-bg-1">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="apartments-info-list apartments-info-list-color mt-40---">
                                                                <ul>
                                                                    <li>
                                                                        <label>Total Area</label>{" "}
                                                                        <span>2800 Sq. Ft</span>
                                                                    </li>
                                                                    <li>
                                                                        <label>Bedroom</label>{" "}
                                                                        <span>150 Sq. Ft</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="apartments-info-list apartments-info-list-color mt-40---">
                                                                <ul>
                                                                    <li>
                                                                        <label>Belcony/Pets</label>{" "}
                                                                        <span>Allowed</span>
                                                                    </li>
                                                                    <li>
                                                                        <label>Lounge</label>{" "}
                                                                        <span>650 Sq. Ft</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="liton_tab_3_4">
                                    <div className="ltn__product-tab-content-inner">
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="apartments-plan-img">
                                                    <img
                                                        src={publicUrl + "assets/img/others/10.png"}
                                                        alt="#"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                                                    <h2>Top Garden</h2>
                                                    <p>
                                                        Enimad minim veniam quis nostrud exercitation
                                                        ullamco laboris. Lorem ipsum dolor sit amet cons
                                                        aetetur adipisicing elit sedo eiusmod
                                                        tempor.Incididunt labore et dolore magna aliqua. sed
                                                        ayd minim veniam.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="product-details-apartments-info-list  section-bg-1">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="apartments-info-list apartments-info-list-color mt-40---">
                                                                <ul>
                                                                    <li>
                                                                        <label>Total Area</label>{" "}
                                                                        <span>2800 Sq. Ft</span>
                                                                    </li>
                                                                    <li>
                                                                        <label>Bedroom</label>{" "}
                                                                        <span>150 Sq. Ft</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="apartments-info-list apartments-info-list-color mt-40---">
                                                                <ul>
                                                                    <li>
                                                                        <label>Belcony/Pets</label>{" "}
                                                                        <span>Allowed</span>
                                                                    </li>
                                                                    <li>
                                                                        <label>Lounge</label>{" "}
                                                                        <span>650 Sq. Ft</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShopDetails;
