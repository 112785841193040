import React from 'react';
import {Button, Modal, Tab, Tabs} from 'react-bootstrap';
import FundTransfer from './FundTransfer';
// import FileUploader2 from '../Helpers/FileUploader2';
import Input from './Input';
import InstantPayments from './InstantPayments';


const FundWalletModal = (props) => {
    return (
        <Modal
            style={{zIndex: 10000}}
            show={props.show}
            onHide={props.onClose}
            size="l"
        >
            <Modal.Header
                closeButton
                style={{padding: 20, backgroundColor: '#334155', color: '#fff', borderRadius: 0}}
            >
                Fund your Tsigiro Usekelo Wallet
            </Modal.Header>
            <Modal.Body>
                <p style={{fontSize: 13}}>Deposit funds into your Tsigiro Usekelo Wallet</p>
                <Tabs
                    className='mb-3'
                    style={{fontSize: 13}}
                >
                    <Tab
                        title="Instant Payment"
                        eventKey="instant-payment"
                    >
                       <InstantPayments/>
                    </Tab>
                    <Tab
                        eventKey="bank-transfer"
                        title="Bank Transfer"
                    >
                    <FundTransfer handleClose={props.onClose}/>
                    </Tab>
                </Tabs>

            </Modal.Body>
        </Modal>
    )
}

export default FundWalletModal