import ApiClient from "./Client";
import { getLoggedInUser } from './Cookie';

const version = '/api/v1';

const requestParams = {
  headers: {
    'x-access-token': getLoggedInUser()?.token
  }
};

class PropertyService {

  getPropertyList = (query) => ApiClient().get(`${version}/properties?category=forPurchase&hasBuyer=false&${query}`, requestParams);

  getSinglePropertyList = (id) => ApiClient().get(`${version}/properties/${id}`, requestParams);

  getSingleInvestmentList = (id) => ApiClient().get(`${version}/properties/${id}`, requestParams);

  getInvestmentsList = (query) => ApiClient().get(`${version}/properties?category=forInvestment&${query}`, requestParams);

  getPropertyTypes = async () => {
    const response = await ApiClient().get(`${version}/propert-types`, requestParams);
    return response.data;
  };

  getPropertyCity = async () => {
    const response = await ApiClient().get(`${version}/propert-cities`, requestParams);
    return response.data;
  };

  requestToPurchase = (propertyID) => ApiClient().post(`${version}/puchase/requests/${propertyID}`, {} , requestParams);

  getUserPurchases = () => ApiClient().get(`${version}/puchase/requests/user`,requestParams);

}


export default new PropertyService();
