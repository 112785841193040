import Properties from './Properties.svg';
import Investments from './Investments.svg';
import {getGlobalSettings, getDecodedUser, getLoggedInUser} from '../../api/Cookie';
import {encoded} from '../../api/RouteHelpers';
// import {encoded} from '../../API/RouterHelpers';

const handleInvestmentsRedirection = () => {
    const validAuth = JSON.stringify(getLoggedInUser());
    const urlEncodedAuth = encoded(validAuth);
    window.location.href = window.location.origin.includes("tsigiro.com") ? process.env.REACT_APP_INVESTMENTS + "/#/?" + urlEncodedAuth : process.env.REACT_APP_DEV_INVESTMENTS + "/#/?" + urlEncodedAuth;
    
};

const handlePropertiesRedirection = () => {
    const validAuth = JSON.stringify(getLoggedInUser());
    const urlEncodedAuth = encoded(validAuth);
    window.location.href = process.env.REACT_APP_PROPERTIES + "?" + urlEncodedAuth;
}

const handleFuneralCoverRedirection = () => {
    // const validAuth = JSON.stringify(getLoggedInUser());
    // const urlEncodedAuth = encoded(validAuth);
    // window.location.href = process.env.REACT_APP_PROPERTIES +"?"+urlEncodedAuth;
}

const handleProfileCoverRedirection = () => {
    window.location.href = window.location.origin.includes("tsigiro.com") ? process.env.REACT_APP_AUTH : process.env.REACT_APP_DEV_AUTH;

}

export const SelectorConstants = [
    {
        redirectURL: handleProfileCoverRedirection,
        image: Investments,
        icon: 'dripicons-user',
        title: 'Profile',
        description: 'Diginified funeral and financial services'
    },
    {
        redirectURL: handlePropertiesRedirection,
        image: Properties,
        title: 'Properties',
        icon: 'dripicons-home',
        description: 'Property Investments made simple and accessible'
    },
    {
        redirectURL: handleInvestmentsRedirection,
        image: Investments,
        icon: 'dripicons-briefcase',
        title: 'Investments',
        description: 'Buy equity and manage your investments with ease'
    },
    {
        redirectURL: handleFuneralCoverRedirection,
        image: Investments,
        title: 'Funeral Cover',
        icon: 'dripicons-pulse',
        description: 'Diginified funeral and financial services'
    },
]